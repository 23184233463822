import { INavItem } from '../components/navbar';
import { getHref } from './env';
import { pathToRegexp } from 'path-to-regexp';
import { IAccount } from '@/service/request/account';
import { FormInstance } from 'rc-field-form';
import { NamePath, ValidateErrorEntity } from 'rc-field-form/lib/interface';

/**
 * global config for antd
 */
export const DEFAULT = {
  pageSize: 10,
};
export const antdPaginationConfig = {
  showQuickJumper: true,
  showSizeChanger: true,
  pageSizeOptions: [10, 20, 30, 50, 100],
  showTotal: (total: number, range: [number, number]) => `${range[0]}-${range[1]} of ${total} items`,
};

export const constants = {
  EMPTY_MODEL_ID: '',
  EMPTY_VERSIN: '',
  EMPTY_TEMPLATE_ID: '',
};
/**
 * the url pathname used
 * all path used is referenced from EPath to avoid inconsistence
 */
export enum EPath {
  DATA_MODEL_LIST = '/data-model/list',
  DATA_MODEL_ITEM_WITH_MODEL_ID_ONLY = '/data-model/:modelId',
  DATA_MODEL_DETAIL = '/data-model/:modelId/:version',
  PIPELINE_LIST = '/pipeline/list',
  PIPELINE_CREATE = '/pipeline/create',
  PIPELINE_EDIT = '/pipeline/:uid/edit',
  LIST_API = '/list-api',
}
export enum ENavLabel {
  PIPELINE_CREATE = 'New Pipeline',
  PIPELINE_EDIT = 'Edit Pipeline',
}
export enum EReactQueryKey {
  DATA_MODEL_LIST = 'dataModelList',
  ACCOUNT = 'account',
  DATA_MODEL_SCHEMA = 'dataModelSchema',
  LOGIN_USER_INFO = 'loginUserInfo',
  SESSION_TAGS = 'sessionTags',
}
/**
 * for navmenu list
 */
export const NAV_LIST: INavItem[] = [
  {
    path: EPath.DATA_MODEL_LIST,
    href: getHref(EPath.DATA_MODEL_LIST),
    label: 'Data Models',
    isActive(location) {
      const { pathname } = location;
      return pathname.startsWith('/data-model');
    },
  },
  {
    path: EPath.PIPELINE_LIST,
    href: getHref(EPath.PIPELINE_LIST),
    label: 'Pipelines',
    isActive(location) {
      const { pathname } = location;
      return pathname.startsWith(EPath.PIPELINE_LIST);
    },
  },
];
export const DYNAMIC_NAV_LIST: INavItem[] = [
  {
    path: EPath.PIPELINE_CREATE,
    href: getHref(EPath.PIPELINE_CREATE),
    label: ENavLabel.PIPELINE_CREATE,
    isActive(location) {
      const { pathname } = location;
      return pathname.startsWith(EPath.PIPELINE_CREATE);
    },
  },
  {
    path: EPath.PIPELINE_EDIT,
    href: getHref(EPath.PIPELINE_EDIT),
    label: ENavLabel.PIPELINE_EDIT,
    isActive(location) {
      const reg = pathToRegexp(EPath.PIPELINE_EDIT);
      const { pathname } = location;
      return reg.test(pathname);
    },
  },
];

/**
 * return accountIdList existed in accountList
 * @param accountIdList
 * @param accountList
 * @returns return @param accountIdList if all of its item exist in accountList, else return a new accountId list.
 */
export function checkAccountIdList(accountIdList: string[], accountList?: IAccount[]): string[] {
  if (!Array.isArray(accountList) || accountList.length === 0) {
    return [];
  }
  const filteredIdList = accountIdList.filter((id) => accountList.findIndex((it) => it.id === id) > -1);
  let changed = false;
  if (filteredIdList.length === 0) {
    changed = true;
    filteredIdList.push(accountList[0].id);
  } else if (filteredIdList.length !== accountIdList.length) {
    changed = true;
  }
  if (changed) {
    return filteredIdList;
  }
  return accountIdList;
}
/**
 * check accountId
 * if accountId exist in accountList, return accountId
 * else return accountList[0].id
 */
export function checkAccountId(accountId: string, accountList?: IAccount[]): string {
  if (!Array.isArray(accountList) || accountList.length === 0) {
    return accountId;
  }
  const account = accountList.find((it) => it.id === accountId);
  if (account) {
    return accountId;
  } else {
    return accountList[0].id;
  }
}

/**
 * wrap form validate, return first error tip
 * @param form
 * @param nameList
 */
export async function validateFormWrapper(form: FormInstance, nameList?: NamePath[]) {
  try {
    await form.validateFields(nameList);
  } catch (err) {
    // console.log('err');
    // console.log(err);
    if ((err as ValidateErrorEntity).hasOwnProperty('errorFields')) {
      const {
        errorFields: [{ name, errors }],
      } = err as ValidateErrorEntity;
      throw new Error(errors[0]);
    }
  }
}

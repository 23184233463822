import { EPath } from '@/service/utils/common';
import { getRelativePath } from '@/service/utils/env';
import pathMatch from '@/service/libs/path-match';
import { getPipelineSettingFromPipelineInfo } from '@/service/utils/pipeline';
import { requestPipelineDetail } from '@/service/request/pipeline';
import { IPipelineSetting } from '@/service/types';
import { useSetState } from 'ahooks';
import { Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import PipelineSetting from './components/Setting';
import './edit.less';
import { requestAndCacheModelList } from '@/service/request/data-model';
import { useQueryClient } from 'react-query';

const pipelineEditUrlReg = pathMatch({})(EPath.PIPELINE_EDIT);

export interface IRefPipelineEdit {
  getPageStatus: () => IPageStatus;
  setPageStatus: (status: Partial<IPageStatus>) => void;
  getPipelineSetting: () => IPipelineSetting | null | undefined;
}

interface IPageStatus {
  uid?: string;
}
function getUidFromLocation() {
  const {
    location: { pathname },
  } = window;
  const relativePath = getRelativePath(pathname);
  const params = pipelineEditUrlReg(relativePath);
  if (params) {
    const { uid } = params;
    if (uid) {
      return uid;
    }
  }
  return undefined;
}
function useGetPipelineSetting() {
  const [isFetching, setIsFetching] = useState(false);
  const [pieplineSetting, setPipelineSetting] = useState<IPipelineSetting | null | undefined>(undefined);
  const queryClient = useQueryClient();
  const getPipelineSetting = useCallback(
    async (uid?: string) => {
      try {
        setIsFetching(true);
        if (uid === undefined) {
          setPipelineSetting(null);
          return;
        }
        const pipelineInfo = await requestPipelineDetail({
          urlParams: {
            uuid: uid as string,
          },
        });
        if (!pipelineInfo) {
          throw new Error(`pipelineInfo is undefined`);
        }
        const dataModelList = await requestAndCacheModelList(queryClient);
        const pipelineSetting = getPipelineSettingFromPipelineInfo(pipelineInfo, dataModelList);
        setPipelineSetting(pipelineSetting);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    },
    [queryClient],
  );

  return {
    isFetching,
    pieplineSetting,
    getPipelineSetting,
  };
}

const PipelineEdit = React.forwardRef<IRefPipelineEdit, {}>((props, ref) => {
  const [pageStatus, setPageStatus] = useSetState<IPageStatus>({});
  const { isFetching, pieplineSetting, getPipelineSetting } = useGetPipelineSetting();

  useEffect(() => {
    const uid = pageStatus.uid;
    getPipelineSetting(uid);
  }, [pageStatus.uid, getPipelineSetting]);
  /** try to get uid from location if it is undefined when mounted */
  useEffect(() => {
    if (!pageStatus.uid) {
      const uid = getUidFromLocation();
      if (uid) {
        setPageStatus({
          uid,
        });
      }
    }
  }, [pageStatus.uid, setPageStatus]);

  React.useImperativeHandle(
    ref,
    () => ({
      getPageStatus() {
        return pageStatus;
      },
      setPageStatus(status: Partial<IPageStatus>) {
        setPageStatus(status);
      },
      getPipelineSetting() {
        return pieplineSetting;
      },
    }),
    [pageStatus, pieplineSetting, setPageStatus],
  );
  // console.log('pipelineInfo');
  // console.log(pipelineInfo);
  // console.log(preSetting);

  return (
    <Spin spinning={isFetching}>
      <div className="page-pipeline-edit">
        <div className="header">
          <div
            style={{
              fontSize: '28px',
              lineHeight: '36px',
              fontWeight: 400,
              color: 'black',
            }}
          >
            Edit Pipeline
          </div>
          <div
            style={{
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 400,
              color: '#4a4a4a',
              fontStyle: 'italic',
            }}
          >
            Edit Data pipeline setup
          </div>
        </div>
        {pageStatus.uid && pieplineSetting && (
          <PipelineSetting
            uid={pageStatus.uid}
            setting={pieplineSetting}
            onSuccess={() => {
              setPageStatus({
                uid: undefined,
              });
            }}
          />
        )}
      </div>
    </Spin>
  );
});

export default PipelineEdit;

/**
 * for common request:
 * it is not allowed to other catergories(such as account, data-model, pipeline)
 * or it is a combination of multi-catergories
 */

import { useQueries } from 'react-query';
import { EReactQueryKey } from '../utils/common';
import { requestData } from './api-system';
import { requestModelList } from './data-model';

/**
 * the data need on app start
 */
export function useRequestPreData() {
  const [requestModelListState, requestLoginUserInfoState] = useQueries([
    {
      queryKey: EReactQueryKey.DATA_MODEL_LIST,
      queryFn: requestModelList,
    },
    {
      queryKey: EReactQueryKey.LOGIN_USER_INFO,
      queryFn: requestLoginUserInfo,
    },
  ]);
  const loading = [requestModelListState].some((it) => it.status === 'loading');
  const error = [requestModelListState].find((it) => it.error instanceof Error);
  return {
    loading,
    error,
    dataModelList: requestModelListState.data,
  };
}

async function requestLoginUserInfo() {
  return requestData({
    url: '/sys-users/login-user-info',
    method: 'get',
  });
}

import React, { useMemo, useState } from 'react';
import { FormInstance } from 'rc-field-form';
import { IPipelineSetting } from '@/service/types';
import usePreviewFileName from '@/pages/pipeline/components/Setting/customHooks/usePreviewFileName';
import { Button, Checkbox, Form, Input } from 'antd';
import { EPartFile, FILE_FORMAT_LIST } from '@/service/utils/pipeline';
import classNames from 'classnames';

import { IConnectionTestStatus } from './typed';
import { SUPPORT_SINGLE_FILE_FORMAT_NAME } from './contant';
import FileFormatWrapper from './FileFormatWrapper';

interface ISettingAWSProps {
  onConnectionTest: (opt?: { showSuccess: boolean }) => void;
  status: IConnectionTestStatus;
  form: FormInstance<IPipelineSetting>;
  granularity: string;
}

const SettingS3IAMProps: React.FC<ISettingAWSProps> = (props) => {
  const {
    onConnectionTest,
    status: { requesting, errorMessage },
    form,
    granularity,
  } = props;

  const { sinkType, sink } = form.getFieldsValue();
  const properties = form.getFieldValue(['sink', 'properties']);

  const { setSuffix, setPrefix, previewFileName } = usePreviewFileName({
    defaultPrefix: sink?.properties?.prefix,
    defaultSuffix: sink?.properties?.suffix,
    granularity: granularity,
  });

  // This useState is used to update the component
  const [formatValue, setFormatValue] = useState<string>();

  const isShowSingleFile = useMemo(() => {
    return SUPPORT_SINGLE_FILE_FORMAT_NAME.includes(properties?.format);
  }, [properties?.format, formatValue]);

  return (
    <>
      <Form.Item<IPipelineSetting>
        key={['sink', 'properties', 'format'].join('')}
        name={['sink', 'properties', 'format']}
        label="File Format"
        initialValue={FILE_FORMAT_LIST[0]}
        rules={[
          {
            required: true,
          },
          () => ({
            validator(_, value) {
              setFormatValue(value);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <FileFormatWrapper />
      </Form.Item>
      {isShowSingleFile && (
        <Form.Item
          key={['sink', 'properties', 'partNum'].join('')}
          name={['sink', 'properties', 'partNum']}
          label="Single File"
          tooltip="Delivered as one file"
          initialValue={EPartFile.DISABLE}
          getValueProps={(v: string) => {
            const checked = v !== EPartFile.DISABLE;
            return {
              checked,
              value: checked,
            };
          }}
          getValueFromEvent={(evt) => {
            try {
              return evt.target.checked ? EPartFile.ENABLE : EPartFile.DISABLE;
            } catch (err) {
              return EPartFile.DISABLE;
            }
          }}
        >
          <Checkbox>enabled</Checkbox>
        </Form.Item>
      )}
      <Form.Item
        key={['sink', 'properties', 'prefix'].join('')}
        name={['sink', 'properties', 'prefix']}
        label="File Name Prefix"
        initialValue={''}
        rules={[
          {
            required: false,
          },
          () => ({
            validator(_, value) {
              setPrefix(value);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key={['sink', 'properties', 'suffix'].join('')}
        name={['sink', 'properties', 'suffix']}
        label="File Name Suffix"
        initialValue={''}
        rules={[
          {
            required: false,
          },
          () => ({
            validator(_, value) {
              setSuffix(value);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <span style={{ color: 'rgba(0,0,0,0.45)' }}>{previewFileName}</span>
      <div className={classNames(['category', errorMessage ? 'error' : ''])}>
        <div className="header">
          <span className="title">{`Authentication for Sink ${sinkType ? sinkType : ''}`}</span>
          <Button
            loading={requesting}
            type="link"
            size="small"
            onClick={(evt) => {
              evt.stopPropagation();
              onConnectionTest({
                showSuccess: true,
              });
            }}
          >
            Test Connection
          </Button>
        </div>
        <Form.Item
          key={['sink', 'properties', 'path'].join('')}
          name={['sink', 'properties', 'path']}
          label="Path"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `path is required for S3-iam sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'roleArn'].join('')}
          name={['sink', 'properties', 'roleArn']}
          label="Role Arn"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `roleArn is required for S3-iam sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'externalId'].join('')}
          name={['sink', 'properties', 'externalId']}
          label="External Id"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `externalId is required for S3-iam sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'awsRegion'].join('')}
          name={['sink', 'properties', 'awsRegion']}
          label="Aws Region"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `awsRegion is required for S3-iam sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'kmsArn'].join('')}
          name={['sink', 'properties', 'kmsArn']}
          label="Kms Arn"
          initialValue={''}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'canonicalId'].join('')}
          name={['sink', 'properties', 'canonicalId']}
          label="Canonical Id"
          initialValue={''}
        >
          <Input />
        </Form.Item>
        {errorMessage ? <div className="error-message">{errorMessage}</div> : null}
      </div>
    </>
  );
};

export default SettingS3IAMProps;

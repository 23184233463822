import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IConnectionTestStatus } from '@/pages/pipeline/components/Setting/typed';
import { FormInstance } from 'rc-field-form';
import { IPipelineSetting } from '@/service/types';
import usePreviewFileName from '@/pages/pipeline/components/Setting/customHooks/usePreviewFileName';
import { SUPPORT_SINGLE_FILE_FORMAT_NAME } from '@/pages/pipeline/components/Setting/contant';
import { Button, Checkbox, Form, Input } from 'antd';
import { EPartFile, FILE_FORMAT_LIST } from '@/service/utils/pipeline';
import FileFormatWrapper from '@/pages/pipeline/components/Setting/FileFormatWrapper';
import classNames from 'classnames';

interface ISettingS3Props {
  onConnectionTest: (opt?: { showSuccess: boolean }) => void;
  status: IConnectionTestStatus;
  form: FormInstance<IPipelineSetting>;
  isEdit: boolean;
  granularity: string;
  showEncrypt: boolean;
  showCredential: () => void;
  encryptCredential: () => void;
  cancelModifyEncryptCredential: () => void;
}

const SettingS3Props: React.FC<ISettingS3Props> = (props) => {
  const {
    onConnectionTest,
    status: { requesting, errorMessage },
    form,
    isEdit,
    granularity,
    showEncrypt,
    showCredential,
    encryptCredential,
    cancelModifyEncryptCredential,
  } = props;
  const { sinkType, sink } = useMemo(() => form.getFieldsValue(), [form]);
  const properties = form.getFieldValue(['sink', 'properties']);

  const { setSuffix, setPrefix, previewFileName } = usePreviewFileName({
    defaultPrefix: sink?.properties?.prefix,
    defaultSuffix: sink?.properties?.suffix,
    granularity: granularity,
  });

  const [encrypt, setEncrypt] = useState<boolean>(true);
  // This useState is used to update the component
  const [formatValue, setFormatValue] = useState<string>();

  const isShowSingleFile = useMemo(() => {
    return SUPPORT_SINGLE_FILE_FORMAT_NAME.includes(properties?.format);
  }, [properties?.format, formatValue]);

  const onEdit = useCallback(() => {
    setEncrypt((encrypt) => !encrypt);
    showCredential();
  }, [showCredential]);

  const onSave = useCallback(() => {
    setEncrypt((encrypt) => !encrypt);
    encryptCredential();
  }, [encryptCredential]);

  const onCancel = useCallback(() => {
    setEncrypt((encrypt) => !encrypt);
    cancelModifyEncryptCredential();
  }, [cancelModifyEncryptCredential]);

  useEffect(() => {
    if (!isEdit) {
      setEncrypt(false);
    }
  }, [isEdit]);

  return (
    <>
      <Form.Item
        key={['sink', 'properties', 'format'].join('')}
        name={['sink', 'properties', 'format']}
        label="File Format"
        initialValue={FILE_FORMAT_LIST[0]}
        rules={[
          {
            required: true,
          },
          () => ({
            validator(_, value) {
              setFormatValue(value);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <FileFormatWrapper />
      </Form.Item>
      {isShowSingleFile && (
        <Form.Item
          key={['sink', 'properties', 'partNum'].join('')}
          name={['sink', 'properties', 'partNum']}
          label="Single File"
          tooltip="Delivered as one file"
          initialValue={EPartFile.DISABLE}
          getValueProps={(v: string) => {
            const checked = v !== EPartFile.DISABLE;
            return {
              checked,
              value: checked,
            };
          }}
          getValueFromEvent={(evt) => {
            try {
              return evt.target.checked ? EPartFile.ENABLE : EPartFile.DISABLE;
            } catch (err) {
              return EPartFile.DISABLE;
            }
          }}
        >
          <Checkbox>enabled</Checkbox>
        </Form.Item>
      )}
      <Form.Item
        key={['sink', 'properties', 'prefix'].join('')}
        name={['sink', 'properties', 'prefix']}
        label="File Name Prefix"
        initialValue={''}
        rules={[
          {
            required: false,
          },
          () => ({
            validator(_, value) {
              setPrefix(value);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key={['sink', 'properties', 'suffix'].join('')}
        name={['sink', 'properties', 'suffix']}
        label="File Name Suffix"
        initialValue={''}
        rules={[
          {
            required: false,
          },
          () => ({
            validator(_, value) {
              setSuffix(value);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <span style={{ color: 'rgba(0,0,0,0.45)' }}>{previewFileName}</span>
      <div className={classNames(['category', errorMessage ? 'error' : ''])}>
        <div className="header">
          <span className="title">{`Authentication for Sink ${sinkType ? sinkType : ''}`}</span>
          <Button
            loading={requesting}
            type="link"
            size="small"
            onClick={(evt) => {
              evt.stopPropagation();
              onConnectionTest({
                showSuccess: true,
              });
            }}
          >
            Test Connection
          </Button>
        </div>

        <Form.Item
          key={['sink', 'properties', 'path'].join('')}
          name={['sink', 'properties', 'path']}
          label="Path"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `path is required for S3 sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'accessID'].join('')}
          name={['sink', 'properties', 'accessID']}
          label="accessID"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `accessID is required for S3 sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <Form.Item
            key={['sink', 'properties', 'accessKey'].join('')}
            name={['sink', 'properties', 'accessKey']}
            label="accessKey"
            required
            rules={[
              {
                type: 'string',
                required: true,
                message: `accessKey is required for S3 sink`,
              },
            ]}
          >
            <Input disabled={showEncrypt && encrypt} />
          </Form.Item>
          {isEdit && showEncrypt && (
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                right: '-160px',
                top: 0,
                gap: '10px',
              }}
            >
              {encrypt ? (
                <>
                  <Button onClick={onEdit}>Edit</Button>
                  <div style={{ width: '76px' }} />
                </>
              ) : (
                <>
                  <Button onClick={onSave}>Save</Button>
                  <Button onClick={onCancel}>Cancel</Button>
                </>
              )}
            </div>
          )}
        </div>
        {errorMessage ? <div className="error-message">{errorMessage}</div> : null}
      </div>
    </>
  );
};

export default SettingS3Props;

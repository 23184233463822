import React, { useMemo } from 'react';
import { IConnectionTestStatus } from '@/pages/pipeline/components/Setting/typed';
import { FormInstance } from 'rc-field-form';
import { IPipelineSetting } from '@/service/types';
import classNames from 'classnames';
import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

interface ISettingBigQueryProps {
  onConnectionTest: (opt?: { showSuccess: boolean }) => void;
  status: IConnectionTestStatus;
  form: FormInstance<IPipelineSetting>;
}

const SettingBigQueryProps: React.FC<ISettingBigQueryProps> = (props) => {
  const {
    onConnectionTest,
    status: { requesting, errorMessage },
    form,
  } = props;
  const { sinkType } = useMemo(() => form.getFieldsValue(), [form]);
  return (
    <>
      <div className={classNames(['category', errorMessage ? 'error' : ''])}>
        <div className="header">
          <span className="title">{`Authentication for Sink ${sinkType ? sinkType : ''}`}</span>
          <Button
            loading={requesting}
            type="link"
            size="small"
            onClick={(evt) => {
              evt.stopPropagation();
              onConnectionTest({
                showSuccess: true,
              });
            }}
          >
            Test Connection
          </Button>
        </div>
        <Form.Item
          key={['sink', 'properties', 'project'].join('')}
          name={['sink', 'properties', 'project']}
          label="Project"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `project is required for BigQuery sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'dataset'].join('')}
          name={['sink', 'properties', 'dataset']}
          label="dataset"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `dataset is required for BigQuery sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'table'].join('')}
          name={['sink', 'properties', 'table']}
          label="table"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `table is required for BigQuery sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'partitionType'].join('')}
          name={['sink', 'properties', 'partitionType']}
          label="partitionType"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `partitionType is required for BigQuery sink`,
            },
          ]}
          initialValue={'NONE'}
        >
          <Select
            options={[
              {
                label: 'NONE',
                value: 'NONE',
              },
              {
                label: 'TIME',
                value: 'TIME',
              },
              {
                label: 'INTEGER',
                value: 'INTEGER',
              },
            ]}
          />
        </Form.Item>
        <Form.Item dependencies={[['sink', 'properties', 'partitionType']]} noStyle={true}>
          {() => {
            const partitionType = form.getFieldValue(['sink', 'properties', 'partitionType']);
            if (partitionType && partitionType !== 'NONE') {
              return (
                <Form.Item
                  key={['sink', 'properties', 'partitionByField'].join('')}
                  name={['sink', 'properties', 'partitionByField']}
                  label="partitionByField"
                  required
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: `partitionByField is required for BigQuery sink`,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              );
            }
            return null;
          }}
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'serviceAccountJSON'].join('')}
          name={['sink', 'properties', 'serviceAccountJSON']}
          label="Service Account JSON"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `serviceAccountJSON is required for BigQuery sink`,
            },
          ]}
        >
          <TextArea rows={3} />
        </Form.Item>
        {errorMessage ? <div className="error-message">{errorMessage}</div> : null}
      </div>
    </>
  );
};

export default SettingBigQueryProps;

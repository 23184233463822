export const descriptionDefinitions = {
  AdRelatedRebuffering: 'Rebuffering duration in milliseconds which started within 30 sec or 1 min after an Ad',
  ASN: 'Autonomous System Number for the ISP',
  AssetName: 'The name of the asset that was viewed',
  AverageBitRate:
    'Average bitrate in kbps at which content was delivered during the session. The ability to determine bitrate depends on the player integration. Not all players can deliver bitrate information.',
  Browser:
    'Name of the browser used by the viewer\'s device.If the viewer is using a mobile app or a connected TV, the value will be "Non-Browser Apps."',
  CDN: 'The CDN associated with the viewing session.',
  City: 'City where content watched',
  ConnectionInducedRebufferingTime:
    'This is rebuffering time in ms not related to seek, divided by total playing time.',
  ContentLength:
    'The length of the asset in milliseconds (ms). Only applicable for VOD. For LIVE, the content length is not known, therefore the value is set to -1.',
  ConvivaSessionID:
    'Unique Conviva session identifier in format: five integer numbers separated by a colon ":".Client ID is part of the Conviva session id, the last 8-number block represents the Client ID.Example:Conviva session id value = "20048757:2397552430:4151350518:1876058113:4487054" thenClient ID = 20048757:2397552430:4151350518:1876058113Session ID = 4487054',
  Country: 'Country where content watched',
  DeviceOS: 'Operating System of the Device',
  EndedStatus:
    "An integer (0-5) showing the status of the session at the end of the day:0 = Not Ended; at the SSD issue time, the session is still active.1 = Gracefully ended; the session ended with a session ended event.2 = Expired due to lack of heartbeat update; we received no heartbeat update for 2 minutes.3 = Expired due to long buffering; the session's lifetime buffering is longer than 30 minutes, Zombie session.4 = Ended due to long pause; the session paused for a continuous period longer than 10 minutes.5 = Ended due to continuous buffering; session was in buffering state for longer than 4 continuous minutes.",
  EndTimeUnix:
    'The time when we received the last session heartbeat within the day.The format is Unix epoch time in seconds.',
  ErrorList:
    'A list of fatal errors that occurred during this session, separated by "&".A session with Startup Time = -1 and Playing Time = 0 and no error list, corresponds to an Exit Before Video Start (EBVS)',
  ExitDuringPreRoll:
    'If a session started, never reported ‘play’ state, and exit happened after ad break start is reported, but no ad break end is reported.',
  HouseholdID: 'Unique identifier for a Household',
  Interrupts:
    'The number of times the session was interrupted for rebuffering. If a viewer paused and resumed a session then that is not counted as interrupt but if that action caused any buffering then it will be counted as an interrupt.',
  IPAddress:
    "The viewer's video playing device public IP address. The IP address - as seen by the Conviva gateway - typically corresponds to the modem gateway for fixed connections or the packet gateway for mobile connections.For European customers, due to legal/privacy reasons, the IP address is not shown.",
  ISP: 'Internet Service Provider name',
  LastCDNEdgeServer: 'The IP address of the CDN Edge Server.',
  LastCDNGroupID: 'The region or pop identifier of the CDN Edge Server.',
  LongRebufferingInterruptions:
    'The total count of number of times a session spent in continuous buffering that are more than 90 seconds. A long buffering could be because of a player being stuck in buffering state and this is excluded from rebuffering time.',
  LongRebufferingTime:
    'The total time in milliseconds that a session spent in continuous buffering that are more than 90 seconds. A long buffering could be because of a player being stuck in buffering state and this is excluded from rebuffering time.',
  MicroBufferingInterruptions:
    "The total count of number of times a session spent in continuous buffering that are less than 200 milliseconds. Micro buffering could result in jittering due to the video playback and this is not excluded from the session's buffering.",
  MicroBufferingTime:
    "The total time in milliseconds that a session spent in continuous buffering that are less than 200 milliseconds. Micro buffering could result in jittering due to the video playback and this is not excluded from the session's buffering.",
  MicroPlayingInterruptions:
    "The total count of number of times a session spent in continuous buffering that are less than 200 milliseconds. Micro buffering could result in jittering due to the video playback and this is not excluded from the session's buffering",
  MicroPlayingTime:
    'The total time in milliseconds that a session spent in continuous play time that are less than 200 milliseconds.',
  PauseTime: 'Total pause time in ms for a session',
  PercentageComplete:
    "The percentage of video content the viewer watched during the session. % Complete is calculated by dividing the total playing time for the session by the total content length. % Complete is rounded to the nearest integer value.A value of -1 means we couldn't obtain content length (for example in live content).A value of 0 means that the video did not start or that the Percentage Complete is less than 1%.",
  PlayingTime:
    'The amount of time in ms when a player is actively displaying video content during a session. Note: This does not include rebuffering time.',
  RebufferingDuringAds:
    'Rebuffering duration in milliseconds happening during the ad playback, using main video session playback',
  BufferingTime:
    'This is the rebuffering time in ms during the session. It does not include the initial buffering at startup. In Experience Insights, we display rebuffering as a percentage of the total viewing experience.',
  RejoinedCount: 'This is the number of times video rejoined after a user seek.',
  SessionTags:
    'Session tags are custom player metadata defined during your Conviva integration and usually reflect your specific business needs and player information.',
  StartTimeUnix:
    'The time when Conviva received the first heartbeat for the session. The format is Unix epoch time in seconds',
  StartupError:
    'If value = true, then video played and there was no startup error.If value = false, then video failed to play and there was a startup error (see Error list).',
  StartupTime:
    "Video Startup Time in ms is the number of seconds between the start of the Conviva monitoring and the first played video frame.If there is a pre-roll ad, the ad time is not counted as part of the startup time.-1 indicates an unsuccessful play (no startup time).-3 indicates the session connected but the client didn't send us the necessary information to determine when the video began playing.",
  State: 'State where content watched',
  StreamURL: 'The last streaming URL used during the session.',
  VideoRestartTime:
    'This is the total amount of time - after seeking is completed by the user - until the video starts playing again. This is the sum of all Video Restart Time, for the entire session in ms.',
  ViewerID:
    'Unique identifier of the viewer (sometimes called subscriber) watching content in that session. This is typically a number, or a hashed/masked identifier without any personally identifiable information. The same ViewerId can have multiple sessions.',
  VPF: 'Video Playback Failures (VPF) occurs when a fatal error causes a video playback to fail.The field is set to TRUE if the session started successfully but ended with a fatal error.',
  VPFBusiness:
    'Video Playback Failures (VPF) Business measures how often Attempts terminated during video playback and a fatal error was reported due to a business logic issue, such as usage limits',
  VPFBusinessErrorList:
    'Video Playback Failures (VPF) Business Error list contains errors (including custom errors) that caused the video playback to fail due to business logic issue.',
  VPFErrorList:
    'Video Playback Failure Error list contains errors (including custom errors) that caused the playback to fail.',
  VPFTechnical:
    'Video Playback Failures (VSF) Technical measures how often Attempts terminated during video playback, and a fatal error was reported due to a technical logic issue, such as prolonged buffering.',
  VPFTechnicalErrorList:
    'Video Playback Failures (VSF) Technical Error list contains errors (including custom errors) that caused the video playback to fail due to technical logic issue.',
  VSFBusiness:
    'Video Start Failures (VSF) Business measures how often Attempts terminated during video startup before the first video frame was played, and a fatal error was reported due to a business logic issue, such as usage limits',
  VSFBusinessErrorList:
    'Video Start Failures (VSF) Business Error list contains errors (including custom errors) that caused the video start to fail due to business logic issue.',
  VSFTechnical:
    'Video Start Failures (VSF) Technical measures how often Attempts terminated during video startup before the first video frame was played, and a fatal error was reported due to a technical logic issue, such as prolonged buffering.',
  VSFTechnicalErrorList:
    'Video Start Failures (VSF) Technical Error list contains errors (including custom errors) that caused the video start to fail due to technical logic issue.',
} as { [key: string]: string };

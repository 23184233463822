import React, { useEffect, useMemo } from 'react';
import { useSetState } from 'ahooks';
import { IDataModelTemplateInfo, IPipelineSetting } from '@/service/types';
import PipelineSetting from './components/Setting';
import './create.less';
import { Spin } from 'antd';
import { useRequestPreData } from '@/service/request/common';
import { checkPipelineSetting, getDefaultPipelineSetting } from '@/service/utils/pipeline';
import { getQuery } from '@/service/libs/util';
import { toDataModelTemplateList } from '@/service/utils/data-model';
import { useRequestAccounts } from '@/service/request/account';

interface IPageStatus {
  setting?: IPipelineSetting | null;
}
export interface IRefPipelineCreate {
  getPageStatus: () => IPageStatus;
  setPageStatus: (status: Partial<IPageStatus>) => void;
}
const PipelineCreate = React.forwardRef<IRefPipelineCreate, {}>((props, ref) => {
  const viewTemplateId = getQuery('viewTemplateId', true);

  const { loading: loadingPre, dataModelList } = useRequestPreData();

  const templateInfo = useMemo(() => {
    const templateList = toDataModelTemplateList(dataModelList);
    if (templateList.length === 0) {
      return;
    }
    let templateInfo: IDataModelTemplateInfo | undefined = templateList.find(
      (it) => it.viewTemplateId === viewTemplateId,
    );
    if (!templateInfo) {
      templateInfo = templateList[0];
    }
    return templateInfo;
  }, [dataModelList, viewTemplateId]);

  const { isLoading: loadingAccounts, data: accountList } = useRequestAccounts(templateInfo?.modelId);

  const loading = loadingPre || loadingAccounts;

  const [pageStatus, setPageStatus] = useSetState<IPageStatus>({
    setting: null,
  });

  const { setting } = pageStatus;

  React.useImperativeHandle(
    ref,
    () => ({
      getPageStatus() {
        return pageStatus;
      },
      setPageStatus(status: Partial<IPageStatus>) {
        const { setting } = status;
        /** check if setting is accurate */
        if (setting) {
          checkPipelineSetting(setting, dataModelList, accountList);
        }
        setPageStatus(status);
      },
    }),
    [pageStatus, accountList, dataModelList, setPageStatus],
  );

  /** get templateInfo from query on refresh */
  useEffect(() => {
    if (loading || !Array.isArray(dataModelList) || !Array.isArray(accountList) || !templateInfo) {
      return;
    }
    const settingByViewTemplateId = Object.assign(getDefaultPipelineSetting(), {
      templateKey: {
        modelId: templateInfo.modelId,
        version: templateInfo.version,
      },
      viewTemplateId: templateInfo.viewTemplateId,
    } as Pick<IPipelineSetting, 'templateKey' | 'viewTemplateId'>);

    checkPipelineSetting(settingByViewTemplateId, dataModelList, accountList);
    setPageStatus({
      setting: settingByViewTemplateId,
    });
  }, [loading, accountList, dataModelList, setPageStatus, templateInfo]);

  return (
    <Spin spinning={setting === null || loading}>
      <div className="page-pipeline-create">
        <div className="header">
          <div
            style={{
              fontSize: '28px',
              lineHeight: '36px',
              fontWeight: 400,
              color: 'black',
            }}
          >
            New Pipeline
          </div>
          <div
            style={{
              fontSize: '16px',
              lineHeight: '22px',
              fontWeight: 400,
              color: '#4a4a4a',
              fontStyle: 'italic',
            }}
          >
            Initiating Data pipeline setup
          </div>
        </div>
        {setting && <PipelineSetting setting={setting} />}
      </div>
    </Spin>
  );
});

export default PipelineCreate;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import type { Moment } from 'moment';
import moment from 'moment';
import TableDataPreview from '@/service/components/TableDataPreview';
import { getUrlHash, setUrlHash } from '@/service/libs/util';
import {
  checkPipelineSetting,
  getDefaultPipelineSetting,
  getPayloadFromPipelineSetting,
  idListToAccountList,
  SINK_TYPE_LIST,
  updateTransformationSettingBySchema,
} from '@/service/utils/pipeline';
import { requestCreatePipeline, requestTestConnection, requestUpdatePipeline } from '@/service/request/pipeline';
import { useRequestModelSchema } from '@/service/request/data-model';
import { IPipelineSetting, IPipelineSettingColumn } from '@/service/types';
import { useSetState } from 'ahooks';
import {
  Button,
  Cascader,
  CheckboxOptionType,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Spin,
  Steps,
} from 'antd';
import { useRequestPreData } from '@/service/request/common';
import { dataModelListToCascaderOptions, formatFreqency, getDataModelVersionInfo } from '@/service/utils/data-model';
import { ENavLabel, EPath, EReactQueryKey, validateFormWrapper } from '@/service/utils/common';
import FormItem from 'antd/lib/form/FormItem';
import { common } from '@/service/store';
import { useQueryClient } from 'react-query';
import './index.less';
import TableColumnConfig from './TableColumnConfig';
import useEncryptCredential from '@/pages/pipeline/components/Setting/customHooks/useEncryptCredential';
import { useRequestAccounts } from '@/service/request/account';

import { IConnectionTestStatus } from './typed';
import SettingS3IAMProps from './SettingS3IAMProps';
import SettingSFTPProps from './SettingSFTPProps';
import SettingSnowflakeProps from './SettingShowflakeProps';
import SettingS3Props from './SettingS3Props';
import SettingBigQueryProps from './SettingBigQueryProps';
import SettingGCSProps from './SettingGCSProps';
import { AUTH_RELATED_PROPS, STEPS } from './contant';

const { Step } = Steps;

interface IPageStatus {
  stepIndex: number;
  modelVisible: boolean;
  requestingCreate: boolean;
  connectionTest: IConnectionTestStatus;
  setting: {
    templateKey: IPipelineSetting['templateKey'];
    sinkType: IPipelineSetting['sinkType'];
    accountIdList: IPipelineSetting['accountIdList'];
  };
}

const formLabelWidth = '800px';

function getDefaultPageStatus(setting: IPipelineSetting): IPageStatus {
  return {
    stepIndex: 0,
    modelVisible: false,
    requestingCreate: false,
    connectionTest: {
      requesting: false,
      errorMessage: '',
    },
    setting: {
      templateKey: setting.templateKey,
      sinkType: setting.sinkType,
      accountIdList: setting.accountIdList,
    },
  };
}

const sinkTypeComponents = {
  GCS: SettingGCSProps,
  BigQuery: SettingBigQueryProps,
  S3: SettingS3Props,
  Snowflake: SettingSnowflakeProps,
  SFTP: SettingSFTPProps,
  'S3-iam': SettingS3IAMProps,
};

const Setting: React.FC<{
  setting?: Partial<IPipelineSetting> | null;
  uid?: string;
  onSuccess?: () => void;
}> = (props) => {
  // console.log(`render Setting`);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm<IPipelineSetting>();

  const { setting: preSetting, uid, onSuccess = () => {} } = props;
  const modelId = form.getFieldValue('templateKey')?.modelId ?? preSetting?.templateKey?.modelId;

  const { loading: loadingPre, dataModelList } = useRequestPreData();
  const { isLoading: loadingAccounts, data: accountList } = useRequestAccounts(modelId);

  const isEdit: boolean = useMemo(() => Boolean(uid && uid.length > 0), [uid]);
  const {
    sameKeyForSftp,
    changeCredentialKeyForSftp,
    noSwitchSinkType,
    resetAuthRelatedProps,
    cacheCredentialRelatedAuth,
    initializeCredentialKey,
    saveAndEncryptCredential,
    getUnencryptedFormValue,
    showCredential,
    cancelModifyCredential,
  } = useEncryptCredential({ form, disabled: !isEdit });

  const setting = useMemo(() => {
    return Object.assign(getDefaultPipelineSetting(), preSetting ? preSetting : {});
  }, [preSetting]);
  const [pageStatus, setPageStatus] = useSetState<IPageStatus>(getDefaultPageStatus(setting));
  const [granularity, setGranularity] = useState<string>('Daily');

  const dataModelVersionInfo = useMemo(() => {
    return getDataModelVersionInfo(pageStatus.setting.templateKey, dataModelList);
  }, [pageStatus.setting.templateKey, dataModelList]);

  const granularityList: CheckboxOptionType[] = useMemo(() => {
    if (!dataModelVersionInfo) {
      return [];
    }
    const { granularities = [] } = dataModelVersionInfo;
    if (granularities.length > 0 && !granularities.find((it) => it.viewTemplateId === setting.viewTemplateId)) {
      const dayViewTemplateId = granularities.find((item) => item.dataGranularity === 'DAY')?.viewTemplateId;
      form.setFieldsValue({ viewTemplateId: dayViewTemplateId ? dayViewTemplateId : granularities[0].viewTemplateId });
    }
    return granularities
      .map((it) => {
        return {
          label: formatFreqency(it.dataGranularity),
          value: it.viewTemplateId,
        };
      })
      .sort(function (a, b) {
        const x = a.label.toLowerCase();
        const y = b.label.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
  }, [dataModelVersionInfo, form, setting]);

  const initSteps = useCallback(() => {
    const step = getUrlHash(STEPS);
    const stepIndex = STEPS.findIndex((it) => it === step);
    if (stepIndex !== -1) {
      setPageStatus({
        stepIndex,
      });
    }
  }, [setPageStatus]);

  /** reinit state on setting change */
  useEffect(() => {
    form.resetFields();
    setPageStatus(getDefaultPageStatus(setting));
    initSteps();
  }, [setting, form, initSteps, setPageStatus]);
  const {
    data: dataSchema,
    refetch: refetchDataSchema,
    isLoading: dataSchemaLoading,
  } = useRequestModelSchema(pageStatus.setting.templateKey.modelId, pageStatus.setting.templateKey.version);

  useEffect(() => {
    form.setFieldsValue(setting);
    // frontend hide credentials
    if (isEdit) {
      initializeCredentialKey();
    }
  }, [form, setting, isEdit]);

  // init state on setting change
  useEffect(() => {
    const isChanged = checkPipelineSetting(setting, dataModelList, accountList);
    if (isChanged) {
      setPageStatus({
        setting: {
          templateKey: setting.templateKey,
          accountIdList: setting.accountIdList,
          sinkType: setting.sinkType,
        },
      });
    }
  }, [setting, dataModelList, accountList, setPageStatus]);

  useEffect(() => {
    if (!dataSchema) {
      form.setFieldsValue({
        transformation: [],
      });
      return;
    }
    form.setFieldsValue({
      transformation: isEdit
        ? updateTransformationSettingBySchema(setting.transformation, dataSchema)
        : dataSchema.map((it) => {
            return {
              ...it,
              selected: true,
              renameTo: '',
              repeatedFieldsFilter: [],
            };
          }),
    });
  }, [dataSchema, setting, form, isEdit]);

  useEffect(() => {
    setPageStatus({
      connectionTest: {
        requesting: false,
        errorMessage: '',
      },
    });
  }, [pageStatus.setting.sinkType, setPageStatus]);

  useEffect(() => {
    const { viewTemplateId } = setting;
    dataModelVersionInfo?.granularities.forEach((it) => {
      if (it.viewTemplateId === viewTemplateId) {
        setGranularity(formatFreqency(it.dataGranularity));
      }
    });
  }, [setting, dataModelVersionInfo]);

  const { cascaderOptions } = useMemo(() => {
    const cascaderOptions = dataModelListToCascaderOptions(dataModelList, isEdit ? setting.viewTemplateId : undefined);
    return { cascaderOptions };
  }, [dataModelList, isEdit, setting.viewTemplateId]);

  const onFormValuesChange = useCallback(
    (changed: IPipelineSetting) => {
      const { setting } = pageStatus;
      Object.entries(changed).forEach(([key]) => {
        switch (key as keyof IPipelineSetting) {
          case 'templateKey':
          case 'sinkType':
          case 'accountIdList':
            setPageStatus({ setting: { ...setting, ...changed } } as Pick<IPageStatus, 'setting'>);
            break;
          case 'viewTemplateId':
            dataModelVersionInfo?.granularities.forEach((it) => {
              if (it.viewTemplateId === changed.viewTemplateId) {
                setGranularity(formatFreqency(it.dataGranularity));
              }
            });
            break;
        }
        if (key === 'sinkType') {
          resetAuthRelatedProps();
          cacheCredentialRelatedAuth();
        }
      });
    },
    [pageStatus, setPageStatus, dataModelVersionInfo, resetAuthRelatedProps, cacheCredentialRelatedAuth],
  );

  const action4ConnectionTest = useCallback(
    async (options?: { showSuccess?: boolean }) => {
      const { showSuccess = false } = options ? options : {};
      const connectionTest: IConnectionTestStatus = {
        requesting: false,
        errorMessage: '',
      };
      const { sinkType } = form.getFieldsValue();
      // console.log(`form.getFieldsValue()`);
      // console.log(form.getFieldsValue());
      try {
        await validateFormWrapper(
          form,
          AUTH_RELATED_PROPS[sinkType].map((it) => ['sink', 'properties', it]),
        );
        connectionTest.requesting = true;
        connectionTest.errorMessage = '';
        setPageStatus({ connectionTest });
        const formData = getUnencryptedFormValue();
        const payload = getPayloadFromPipelineSetting(formData, []);
        await requestTestConnection({ data: payload.properties.sink });
        if (showSuccess) {
          message.success(`connect success`);
        }
      } catch (err: any) {
        if (err instanceof Error) {
          const { message } = err;
          connectionTest.errorMessage = message;
          throw err;
        }
      } finally {
        connectionTest.requesting = false;
        setPageStatus({ connectionTest });
      }
    },
    [form, setPageStatus, getUnencryptedFormValue],
  );

  const firstAccount = useMemo(() => {
    const accountIdList = pageStatus.setting.accountIdList;
    const filteredAccounts = idListToAccountList(accountIdList, accountList);
    return filteredAccounts[0];
  }, [pageStatus.setting.accountIdList, accountList]);

  const stepChange = (value: number) => {
    setPageStatus({
      stepIndex: value,
    });
    setUrlHash(STEPS[value]);
  };

  const CurrentComponent = sinkTypeComponents[pageStatus.setting.sinkType] || null;

  const commonProps = {
    form: form,
    onConnectionTest: action4ConnectionTest,
    status: pageStatus.connectionTest,
  };

  const additionalProps = {
    GCS: {
      isEdit: isEdit,
      granularity: granularity,
      showEncrypt: noSwitchSinkType(),
      showCredential: showCredential,
      encryptCredential: saveAndEncryptCredential,
      cancelModifyEncryptCredential: cancelModifyCredential,
    },
    S3: {
      isEdit: isEdit,
      granularity: granularity,
      showEncrypt: noSwitchSinkType(),
      showCredential: showCredential,
      encryptCredential: saveAndEncryptCredential,
      cancelModifyEncryptCredential: cancelModifyCredential,
    },
    SFTP: {
      isEdit: isEdit,
      granularity: granularity,
      sameKeyForSftp: sameKeyForSftp,
      changeCredentialKeyForSftp: changeCredentialKeyForSftp,
      showEncrypt: noSwitchSinkType(),
      showCredential: showCredential,
      encryptCredential: saveAndEncryptCredential,
      cancelModifyEncryptCredential: cancelModifyCredential,
    },
    BigQuery: {},
    Snowflake: {},
    'S3-iam': {
      granularity: granularity,
    },
  };

  // @ts-ignore
  return (
    <Spin spinning={loadingPre || loadingAccounts || dataSchemaLoading} tip="Loading">
      <div className="component-pipeline-setting">
        <div className="nav">
          <Steps current={pageStatus.stepIndex} onChange={stepChange}>
            {STEPS.map((i, index) => {
              return (
                <Step
                  key={i}
                  title={`Step ${index + 1}`}
                  description={i}
                  status={pageStatus.stepIndex === index ? 'process' : 'wait'}
                />
              );
            })}
          </Steps>
          <div className="nav-buttons">
            {pageStatus.stepIndex === STEPS.length - 1 ? (
              <Button
                type="primary"
                ghost
                onClick={async () => {
                  console.log(getUnencryptedFormValue());
                  try {
                    // @ts-ignore
                    const nameList = form
                      // @ts-ignore
                      .getInternalHooks('RC_FORM_INTERNAL_HOOKS')
                      .getFields()
                      // @ts-ignore
                      .map((it) => it.name);
                    const nameListToCheck = nameList.filter(
                      (it: any) => !(Array.isArray(it) && it.length === 1 && it[0] === 'name'),
                    );
                    await validateFormWrapper(form, nameListToCheck);
                    setPageStatus({
                      modelVisible: true,
                    });
                  } catch (err) {
                    if (err instanceof Error) {
                      if (err.message === 'account can not be empty') {
                        stepChange(0);
                      } else {
                        stepChange(1);
                      }
                      message.error(err.message);
                    }
                  }
                }}
              >
                Finish
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
        <section
          className={classNames('setting-step select-data-model', {
            active: pageStatus.stepIndex === 0,
          })}
        >
          <Form
            style={{
              width: formLabelWidth,
              marginLeft: '20px',
              marginTop: '20px',
            }}
            labelCol={{
              span: 6,
            }}
            labelAlign="left"
            form={form}
            // onFinish={() => {
            //   console.log(form.getFieldsValue());
            // }}
            // onValuesChange={(...args) => {
            //   console.log('onValuesChange');
            //   console.log(args);
            // }}
            onValuesChange={onFormValuesChange}
            onFieldsChange={(...args) => {
              console.log('onFieldsChange');
              console.log(args);
            }}
            preserve={false}
          >
            <Form.Item
              key="modelId"
              name="templateKey"
              label="Data Model"
              getValueProps={(templateKey: IPipelineSetting['templateKey']) => {
                if (templateKey) {
                  const { modelId, version } = templateKey;
                  return { value: [modelId, version] };
                } else {
                  return { value: [] };
                }
              }}
              getValueFromEvent={(value: string[], selectedOptions: any) => {
                return {
                  modelId: value[0],
                  version: value[1],
                };
              }}
              {...(isEdit
                ? {
                    validateStatus: 'validating',
                    help: 'data model is not allowed to change during edit pipeline',
                  }
                : {
                    required: true,
                  })}
            >
              <Cascader
                // disabled={isEdit}
                size="small"
                options={cascaderOptions}
                allowClear={false}
                placeholder="Please select data model"
                showSearch={{
                  filter(inputValue: string, path: any[]) {
                    return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
                  },
                }}
              />
            </Form.Item>
            <Form.Item
              key="accountIdList"
              name="accountIdList"
              label="C3 Accounts"
              required
              rules={[
                {
                  validator(rule, value) {
                    return new Promise<void>((res, rej) => {
                      if (Array.isArray(value) && value.length === 0) {
                        rej(`account can not be empty`);
                      }
                      res();
                    });
                  },
                },
              ]}
            >
              <Select
                size="small"
                mode="multiple"
                allowClear={true}
                showSearch={true}
                filterOption={(key, option) => {
                  if (!option) {
                    return false;
                  }
                  const { label = '', value = '' } = option;
                  return [label, value].join('').toLowerCase().indexOf(key.toLowerCase()) > -1;
                }}
                // onChange={(v) => {
                //   console.log(`selected: ${v}`);
                // }}
                options={
                  accountList
                    ? accountList.map((it) => {
                        return {
                          value: it.id,
                          label: it.name,
                        };
                      })
                    : []
                }
              />
            </Form.Item>
          </Form>
          <div
            style={{
              borderTop: '1px solid #e6e6e6',
            }}
          >
            <div
              style={{
                marginTop: '16px',
                paddingLeft: '20px',
                fontSize: '24px',
                color: '#4a4a4a',
                fontWeight: '600',
              }}
            >
              Data Preview
            </div>
            {firstAccount ? (
              <div
                style={{
                  paddingLeft: '20px',
                  marginBottom: '12px',
                }}
              >
                for account {firstAccount.name}
              </div>
            ) : null}
            <TableDataPreview schema={dataSchema} templateKey={pageStatus.setting.templateKey} account={firstAccount} />
          </div>
        </section>
        <section
          className={classNames('setting-step select-data-destination', {
            active: pageStatus.stepIndex === 1,
          })}
        >
          <Form
            style={{
              width: formLabelWidth,
              marginLeft: '20px',
              marginTop: '20px',
            }}
            labelCol={{
              span: 6,
            }}
            labelAlign="left"
            form={form}
            onValuesChange={onFormValuesChange}
            preserve={false}
          >
            <div className="section-title">Destination</div>
            <Form.Item key="sinkType" name="sinkType" label="Sink Type" required>
              <Select
                options={SINK_TYPE_LIST.map((it) => {
                  return {
                    label: it,
                    value: it,
                  };
                })}
              />
            </Form.Item>
            {CurrentComponent ? (
              // @ts-ignore
              <CurrentComponent {...commonProps} {...(additionalProps[pageStatus.setting.sinkType] || {})} />
            ) : null}
            <div className="section-title">Delivery Frequency</div>
            <Form.Item
              key="duration"
              name="duration"
              label="Pipeline End Date"
              getValueProps={(range: (Moment | null)[]) => {
                if (Array.isArray(range) && range.length >= 2) {
                  return { value: range[1] };
                }
                return { value: null };
              }}
              getValueFromEvent={(date: Moment) => {
                return [null, date ? date.endOf('day') : null];
              }}
            >
              <DatePicker
                style={{
                  minWidth: '160px',
                }}
                disabledDate={(currentDate) => currentDate < moment().subtract(1, 'day')}
              />
            </Form.Item>
            {granularityList.length > 0 && (
              <Form.Item key="viewTemplateId" name="viewTemplateId" label="Granularity">
                <Radio.Group name="radiogroup" disabled={isEdit} options={granularityList} />
              </Form.Item>
            )}
            {isEdit && (
              <span style={{ color: 'rgba(0,0,0,0.45)' }}>
                *You can't edit the frequency for an existing pipeline. Please create a new pipeline for the desired
                frequency.
              </span>
            )}
          </Form>
        </section>

        <section
          className={classNames('setting-step configure-data', {
            active: pageStatus.stepIndex === 2,
          })}
        >
          <Form
            style={{
              // width: formLabelWidth,
              marginLeft: '20px',
              marginTop: '20px',
              marginRight: '20px',
            }}
            // labelCol={{
            //   span: 6,
            // }}
            labelAlign="left"
            form={form}
            onValuesChange={onFormValuesChange}
          >
            <FormItem
              label=""
              name="transformation"
              key="transformation"
              getValueProps={(transformation: IPipelineSettingColumn) => {
                return {
                  transformation,
                };
              }}
              getValueFromEvent={(value: string[]) => {
                return value;
              }}
            >
              <TableColumnConfig setting={preSetting} form={form} />
            </FormItem>
          </Form>
        </section>

        <Modal
          className="save-pipeline-modal"
          title={isEdit ? 'Edit Pipeline' : 'New Pipeline'}
          visible={pageStatus.modelVisible}
          onOk={async () => {
            const formData = getUnencryptedFormValue();
            // console.log(`formData`);
            // console.log(formData);
            if (dataSchema) {
              const payload = getPayloadFromPipelineSetting(formData, accountList ? accountList : []);
              try {
                await validateFormWrapper(form);
                // console.log('payload');
                // console.log(payload);
                setPageStatus({
                  requestingCreate: true,
                });
                await action4ConnectionTest();
                if (uid) {
                  await requestUpdatePipeline({
                    data: payload,
                    urlParams: {
                      uid,
                    },
                    showErrorMessage: false,
                  });
                  const navIndex = common.navList.findIndex((it) => it.label === ENavLabel.PIPELINE_EDIT);
                  if (navIndex > -1) {
                    const navList = [...common.navList];
                    navList.splice(navIndex, 1);
                    common.setNavList(navList);
                  }
                } else {
                  await requestCreatePipeline({ data: payload, showErrorMessage: false });
                  /*
                   * invalidate current dataModelList if create pipeline success
                   * as activePipelineCount of dataModel is changed
                   */
                  await queryClient.invalidateQueries(EReactQueryKey.DATA_MODEL_LIST, {
                    refetchActive: false,
                  });
                  await queryClient.invalidateQueries('pipelineList');
                  await queryClient.invalidateQueries('statistic');
                  const navIndex = common.navList.findIndex((it) => it.label === ENavLabel.PIPELINE_CREATE);
                  if (navIndex > -1) {
                    const navList = [...common.navList];
                    navList.splice(navIndex, 1);
                    common.setNavList(navList);
                  }
                }
                message.success(`${uid ? 'edit pipeline' : 'create pipeline'} ${formData.name} success`);
                setPageStatus({
                  modelVisible: false,
                });
                onSuccess();
                navigate(EPath.PIPELINE_LIST);
              } catch (err) {
                // console.log('err');
                // console.log(err);
                if (err instanceof Error) {
                  message.error(err.message);
                }
              } finally {
                setPageStatus({
                  requestingCreate: false,
                });
              }
            } else {
              message.error(`dataSchema for dataModel ${pageStatus.setting.templateKey.modelId} is not ready`);
              await refetchDataSchema();
            }
          }}
          onCancel={() => {
            setPageStatus({
              modelVisible: false,
            });
          }}
          okButtonProps={{ disabled: pageStatus.requestingCreate, loading: pageStatus.requestingCreate }}
          cancelButtonProps={{ disabled: pageStatus.requestingCreate }}
          cancelText="Cancel"
          okText="Save"
          closable={!pageStatus.requestingCreate}
          centered={true}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              key="name"
              name="name"
              label="Name"
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'pipeline name is required',
                },
              ]}
              {...(isEdit
                ? {
                    validateStatus: 'validating',
                    help: 'pipeline name is not allowed to change during edit pipeline',
                  }
                : {
                    required: true,
                  })}
            >
              <Input disabled={isEdit} />
            </Form.Item>
          </Form>
          {/* Wait for Jingyuan to determine the backend name rule content.(next release) */}
          {/* <div className="modal-rule">
            <span>Rules:</span>
            <div className="modal-rule-content">
              <ol>
                <li> 1. All lower case</li>
                <li> 2. xxx</li>
              </ol>
            </div>
          </div> */}
        </Modal>
      </div>
    </Spin>
  );
};

export default Setting;

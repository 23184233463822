import { isNumber, isString } from './util';

/**
 * new Date(yyyy-mm-dd hh:mm:ss.SSS) is not supported in safari
 * change yyyy-mm-dd hh:mm:ss.SSS to yyyy/mm/dd hh:mm:ss.SSS
 * yyyy-mm-dd
 * yyyy-mm-dd hh:mm:ss.SSS
 * yyyy-mm-ddThh:mm:ss.SSS
 */
export function strToDate(val: string): Date {
  const valFixed = val.replace(/[-T]/g, (m) => (m === '-' ? '/' : ' ')).replace(/\.\d+/, '');
  // console.log('date', val, valFixed);
  return new Date(valFixed);
}
export function toDate(dt: string | number | Date | null): Date | null {
  let date: Date | null = null;
  if (!dt) {
    return null;
  }
  if (isString(dt)) {
    date = strToDate(dt as string);
  } else if (isNumber(dt)) {
    date = new Date(dt);
  } else if (dt instanceof Date) {
    date = dt;
  }
  return date;
}

/**
 * transfer to formated date string
 * @date timestamp of date
 * @fmt the format of result, such as yyyy-MM-dd hh:mm:ss.SSS
 */
export function formatDate(dt: string | number | Date | null, fmt: string) {
  const date = toDate(dt);
  if (!(date instanceof Date)) {
    return '';
  }

  const o: {
    [key: string]: any;
  } = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S+': date.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] + '' : ((k === 'S+' ? '000' : '00') + o[k]).substr(('' + o[k]).length),
      );
    }
  }
  return fmt;
}

import { Modal } from 'antd';
import moment from 'moment';
import { NavigateFunction } from 'react-router-dom';
import { checkAccountIdList, constants, ENavLabel, EPath } from './common';
import { getAppHubProps } from './env';
import { toUrl } from '../libs/util';
import { IAccount } from '../request/account';
import {
  IPipelinePayload,
  IDataModelSchema,
  IPipelineInfo,
  IPipelineSetting,
  IPipelineSettingColumn,
  IPipelineTransformation,
  IDataModelItem,
  IDataModelTemplateInfo,
  TFileFormat,
  TSinkType,
  IColumnFilter,
  IFilterItem,
  ISessionTags,
} from '../types';
import { globalInfo, common } from '../store';
import { checkViewTemplate, formatFreqency, getDataModelTemplateInfo, toDataModelTemplateList } from './data-model';
import { TConfigRule } from '@/pages/pipeline/components/RuleMapper';
import { descriptionDefinitions } from '@/pages/pipeline/components/Setting/descriptionDefinitions';

export const FILE_FORMAT_TO_CONTENT_TYPE: {
  [format in TFileFormat]: string;
} = {
  csv: 'csv',
  avro: 'avro',
  // json: 'json',
  // orc: 'octet-stream',
  parquet: 'octet-stream',
};
export const FILE_FORMAT_LIST: TFileFormat[] = Object.keys(FILE_FORMAT_TO_CONTENT_TYPE) as TFileFormat[];

export enum EPartFile {
  /** 1 means deliver as only a single file while -1 means deliver as a fold with one or multiply files */
  ENABLE = '1',
  DISABLE = '-1',
}

// const sinkTypeList: TSinkType[] = ['GCS', 'BigQuery', 'S3', 'Snowflake'];
export const SINK_TYPE_LIST: TSinkType[] = ['GCS', 'S3', 'SFTP', 'S3-iam'];

/**
 * All the filter condition for Column, with the formation of map from value to label
 */
export const COLUMN_FILTER_MAP = {
  contains: 'contains',
  'not contains': 'not contains',
  equals: 'equals',
  'not equals': 'not equals',
};

export const credentialKeys = ['serviceAccountJSON', 'accessKey', 'privateKey', 'password'];

export function getDefaultPipelineSetting(): IPipelineSetting {
  return {
    name: '',
    templateKey: {
      modelId: constants.EMPTY_MODEL_ID,
      version: constants.EMPTY_VERSIN,
    },
    viewTemplateId: constants.EMPTY_TEMPLATE_ID,
    accountIdList: [],
    sinkType: 'GCS',
    sink: {
      properties: {},
    },
    duration: [null, null],
    transformation: [],
    emailList: '',
    repeatedFieldsFilter: [],
  };
}

export function idListToAccountList(idList: string[], accountList?: IAccount[]) {
  if (!Array.isArray(idList) || !Array.isArray(accountList)) {
    return [];
  }
  // make sure accounts keeps the same order as idList
  return idList.map((id) => accountList.find((it) => it.id === id)).filter((it) => Boolean(it));
}

function columnFilterToConfigRule(filter: IColumnFilter): {
  fieldName: string;
  rule: TConfigRule;
} | null {
  if (!filter) {
    return null;
  }
  if (!Array.isArray(filter.filters)) {
    return null;
  }
  let fieldName = '';
  const rule: TConfigRule = filter.filters
    .filter((it) => {
      return Array.isArray(it.filterItems) && it.filterItems.length > 0;
    })
    .map((columnFilter) => {
      const { filterItems } = columnFilter;
      fieldName = filterItems[0]['fieldName'];
      return filterItems
        .filter((it) => it.fieldName === fieldName)
        .map((it) => {
          return it;
        });
    });
  if (!fieldName) {
    return null;
  }
  return { fieldName, rule };
}

export function getPayloadFromPipelineSetting(formData: IPipelineSetting, accounts: IAccount[]): IPipelinePayload {
  const {
    name,
    templateKey: { modelId, version },
    accountIdList,
    sinkType,
    sink,
    duration,
    transformation,
    viewTemplateId,
    encrypted,
  } = formData;
  const sinkProperties: IPipelinePayload['properties']['sink']['properties'] = [];
  if (sink.properties) {
    Object.entries(sink.properties).forEach(([key, value]) => {
      /** not use format {key, value} to avoid property value is missing when value = '' */
      sinkProperties.push({
        key,
        // Prevent avro.gz format in the data already maintained
        value: value?.indexOf('avro.gz') > 0 ? 'avro' : value,
      });
      if (key === 'format' && Object.keys(FILE_FORMAT_TO_CONTENT_TYPE).indexOf(value) > -1) {
        sinkProperties.push({
          key: 'contentType',
          value: FILE_FORMAT_TO_CONTENT_TYPE[value as keyof typeof FILE_FORMAT_TO_CONTENT_TYPE],
        });
      }
    });
  }
  const keepFields: IPipelineTransformation['keepFields'] = [];
  const renameFields: IPipelineTransformation['renameFields'] = [];
  const columnFilterList: IColumnFilter[] = [];
  const sessionTags: ISessionTags[] = [];
  transformation.forEach((it) => {
    const { name, selected, renameTo, filter, repeatedFieldsFilter } = it;
    if (selected) {
      keepFields.push(name);
      if (renameTo) {
        renameFields.push({
          oldName: name,
          newName: renameTo,
        });
      }
    }
    if (repeatedFieldsFilter && repeatedFieldsFilter[0]?.selectedValues.length > 0) {
      sessionTags.push(...repeatedFieldsFilter);
    }
    if (Array.isArray(filter) && filter.length > 0) {
      const columnFilter: IColumnFilter = {
        filters: filter.map((andRule) => {
          return {
            filterItems: andRule.map((orRule) => {
              return {
                fieldName: name,
                condition: orRule.condition,
                value: orRule.value,
              } as IFilterItem;
            }),
            conjunction: 'or',
          };
        }),
        conjunction: 'and',
      };
      columnFilterList.push(columnFilter);
    }
  });
  let startTime = '';
  let endTime = '';
  if (duration[0]) {
    startTime = duration[0].clone().utc().format('YYYY-MM-DD HH:mm:ss');
  }
  if (duration[1]) {
    endTime = duration[1].clone().utc().format('YYYY-MM-DD HH:mm:ss');
  }
  // console.log('duration');
  // console.log(duration);
  // console.log(startTime);
  // console.log(endTime);
  const customerInfos = accounts.filter((it) => accountIdList.indexOf(it.id) > -1);
  const appHubProps = getAppHubProps();
  let createUser = '';
  let userEmail = '';
  try {
    if (appHubProps) {
      const {
        user: {
          user: { userName },
        },
      } = appHubProps;
      if (userName.indexOf('@') > -1) {
        createUser = userName.split('@')[0];
        userEmail = userName;
      }
    }
  } catch (err) {
    console.log(err);
  }
  return {
    // createUser can be empty, but must exist
    createUser,
    name,
    modelId,
    viewTemplateVersion: version,
    viewTemplateId,
    customer: {
      customerInfos,
    },
    properties: {
      emailList: userEmail,
      sink: {
        type: sinkType,
        properties: sinkProperties,
        encrypted,
      },
      transformation: {
        keepFields,
        renameFields,
        repeatedFieldsFilter: sessionTags,
      },
      filter: { filters: columnFilterList, conjunction: 'and' },
      scheduler: {
        startTime,
        endTime,
      },
    },
  };
}

/**
 * Merge passed props with default props
 */
export function getColumnProps(
  props: Pick<IPipelineSettingColumn, 'name'> & Partial<Omit<IPipelineSettingColumn, 'name'>>,
): IPipelineSettingColumn {
  const defaultConfig: IPipelineSettingColumn = {
    name: props.name,
    selected: true,
    renameTo: '',
  };
  return Object.assign(defaultConfig, props);
}

export function getColumnDescription({
  descriptionArr = descriptionDefinitions,
  columnName,
}: {
  descriptionArr?: { [key: string]: string };
  columnName: string;
}) {
  const findDescriptionName = Object.keys(descriptionArr).find(
    (it) => it.replace(/\s+/g, '').toUpperCase() === columnName.replace(/\s+/g, '').toUpperCase(),
  );
  return findDescriptionName ? { description: descriptionArr[findDescriptionName] } : { description: '' };
}

export function updateTransformationSettingBySchema(
  transformations: IPipelineSettingColumn[],
  schema?: IDataModelSchema[],
): IPipelineSettingColumn[] {
  if (!schema) {
    return transformations;
  }
  return schema.map((column) => {
    const res: IPipelineSettingColumn = { ...column, selected: true, renameTo: '', filter: [] };
    const target = transformations.find((it) => it.name === column.name);
    const description = getColumnDescription({ columnName: column.name });
    return Object.assign(res, target ? target : { selected: false }, description);
  });
}

export function getPipelineSettingFromPipelineInfo(
  pipelineInfo: IPipelineInfo,
  dataModelList: IDataModelItem[],
): IPipelineSetting | null {
  const {
    uuid,
    name,
    modelId,
    viewTemplateId,
    customer: { customerInfos },
    properties: {
      scheduler: { startTime, endTime },
      sink: { type: sinkType, properties: sinkProperties },
      transformation: { keepFields = [], renameFields = [], repeatedFieldsFilter = [] },
      filter,
      emailList,
    },
  } = pipelineInfo;
  const templateInfo = getDataModelTemplateInfo(viewTemplateId, dataModelList);
  if (!templateInfo) {
    console.error(`viewTemplateId ${viewTemplateId} not found in DataModel List`);
    return null;
  }
  if (templateInfo.uuid !== modelId) {
    console.error(`modelId is not correct`);
  }

  const transformation: IPipelineSettingColumn[] = keepFields.map((fieldName) => {
    let renameTo = '';
    let sessionTags: ISessionTags[] = [];
    if (renameFields) {
      const target = renameFields.find((it) => it.oldName === fieldName);
      if (target) {
        renameTo = target.newName;
      }
    }
    if (repeatedFieldsFilter && repeatedFieldsFilter.length > 0) {
      const sessionTag = repeatedFieldsFilter.find((value) => value.name === fieldName);
      if (sessionTag) {
        sessionTags = [sessionTag];
      }
    }
    return {
      name: fieldName,
      selected: true,
      renameTo,
      filter: [],
      repeatedFieldsFilter: sessionTags,
    };
  });
  if (filter && Array.isArray(filter.filters)) {
    filter.filters.forEach((columnFilter) => {
      const configRuleInfo = columnFilterToConfigRule(columnFilter);
      if (!configRuleInfo) {
        return;
      }
      const { fieldName, rule } = configRuleInfo;
      const targetColumn = transformation.find((it) => it.name === fieldName);
      if (targetColumn) {
        targetColumn.filter = rule;
      } else {
        transformation.push(
          getColumnProps({
            name: fieldName,
            filter: rule,
          }),
        );
      }
    });
  }

  const res: IPipelineSetting = {
    uuid,
    name,
    templateKey: {
      modelId: templateInfo.modelId,
      version: templateInfo.version,
    },
    viewTemplateId,
    accountIdList: Array.isArray(customerInfos) ? customerInfos.map((it) => it.id) : [],
    sinkType,
    sink: {
      properties: {},
    },
    transformation,
    duration: [],
    emailList,
    repeatedFieldsFilter,
  };
  sinkProperties.forEach(({ key, value }) => {
    // @ts-ignore
    res.sink.properties[key] = value;
  });
  if (startTime) {
    res.duration.push(moment(moment.utc(startTime).local().format('YYYY-MM-DD HH:mm:ss')));
  } else {
    res.duration.push(null);
  }
  if (endTime) {
    res.duration.push(moment(moment.utc(endTime).local().format('YYYY-MM-DD HH:mm:ss')));
  } else {
    res.duration.push(null);
  }
  return res;
}

/**
 * check, update props of pipelineSetting if necessary
 * @param setting
 * @param dataModelList
 * @param accountList
 */
export function checkPipelineSetting(
  setting: IPipelineSetting,
  dataModelList?: IDataModelItem[],
  accountList?: IAccount[],
): boolean {
  const { templateKey: templateKeys, viewTemplateId, accountIdList, sinkType } = setting;
  const originTemplateKeys = {
    ...templateKeys,
    viewTemplateId,
  };
  /** set default value on create */
  let isChanged = false;
  if (SINK_TYPE_LIST.indexOf(sinkType) === -1) {
    setting.sinkType = SINK_TYPE_LIST[0];
    isChanged = true;
  }
  if (dataModelList) {
    const checkTemplateKeys = checkViewTemplate(originTemplateKeys, dataModelList);
    if (originTemplateKeys !== checkTemplateKeys) {
      Object.assign(setting, {
        templateKey: { modelId: checkTemplateKeys.modelId, version: checkTemplateKeys.version },
        viewTemplateId: checkTemplateKeys.viewTemplateId,
      } as Pick<IPipelineSetting, 'templateKey' | 'viewTemplateId'>);
      isChanged = true;
    }
  }
  if (accountList) {
    const checkedAccountIdList = checkAccountIdList(accountIdList, accountList);
    // console.log(`checkedAccountIdList, accountIdList`);
    // console.log(checkedAccountIdList, accountIdList);
    if (checkedAccountIdList !== accountIdList) {
      setting.accountIdList = checkedAccountIdList;
      isChanged = true;
    }
  }
  return isChanged;
}

export async function toPagePipelineEdit(uid: string, navigate: NavigateFunction) {
  const { refComponentPipelineEdit } = globalInfo;
  try {
    await new Promise<void>((res, rej) => {
      /** navigate to EPath.PIPELINE_EDIT if component PipelineEdit is not mounted */
      if (!refComponentPipelineEdit) {
        return res();
      }
      /** navigate to EPath.PIPELINE_EDIT if nav item ENavLabel.PIPELINE_EDIT not exist */
      if (!common.navList.find((it) => it.label === ENavLabel.PIPELINE_EDIT)) {
        return res();
      }
      const pageStatus = refComponentPipelineEdit.getPageStatus();
      /** navigate to EPath.PIPELINE_EDIT if uid is undefined */
      if (!pageStatus.uid) {
        return res();
      }
      const pipelineSetting = refComponentPipelineEdit.getPipelineSetting();
      let pipelineName = '';
      if (pipelineSetting) {
        pipelineName = pipelineSetting.name;
        /** if current pipeline in editting, navigate to EPath.PIPELINE_EDIT */
        if (pipelineSetting.uuid === uid) {
          return res();
        }
      }
      /** ask for user if want to edit a different pipeline */
      Modal.confirm({
        content: `Pipeline ${pipelineName} is in Editing, are you sure to override it?`,
        onOk() {
          res();
        },
        onCancel() {
          rej();
        },
      });
    });
    navigate(
      toUrl({
        path: EPath.PIPELINE_EDIT,
        params: {
          uid,
        },
      }),
    );
    /** make sure refComponentPipelineEdit is mounted */
    let count = 0;
    while (!globalInfo.refComponentPipelineEdit && count++ < 50) {
      await new Promise((res) => setTimeout(res, 5));
    }
    if (globalInfo.refComponentPipelineEdit) {
      globalInfo.refComponentPipelineEdit.setPageStatus({
        uid,
      });
    }
  } catch (err) {
    /** Ignore */
  }
}

export function closePagePipelineEdit() {
  const { refComponentPipelineEdit } = globalInfo;
  if (refComponentPipelineEdit) {
    refComponentPipelineEdit.setPageStatus({
      uid: undefined,
    });
  }
}

export async function toPagePipelineCreate(preSetting: IPipelineSetting, navigate: NavigateFunction) {
  const { refComponentPipelineCreate } = globalInfo;
  try {
    await new Promise<void>((res, rej) => {
      /** navigate to EPath.PIPELINE_CREATE if component PipelineCreate is not mounted */
      if (!refComponentPipelineCreate) {
        return res();
      }
      /** navigate to EPath.PIPELINE_CREATE if nav item ENavLabel.PIPELINE_CREATE not exist */
      if (!common.navList.find((it) => it.path === EPath.PIPELINE_CREATE)) {
        return res();
      }
      const { setting: currentSetting } = refComponentPipelineCreate.getPageStatus();
      /** navigate to EPath.PIPELINE_EDIT if uid is undefined */
      if (currentSetting) {
        /** ask for user if want to edit a different pipeline */
        Modal.confirm({
          content: `A pipeline is in creating, are you sure to override it?`,
          onOk() {
            res();
          },
          onCancel() {
            rej();
          },
        });
      }
    });
    navigate(
      toUrl({
        path: EPath.PIPELINE_CREATE,
        query: {
          viewTemplateId: preSetting.viewTemplateId,
        },
      }),
    );
    /** make sure refComponentPipelineCreate is mounted */
    let count = 0;
    while (!globalInfo.refComponentPipelineCreate && count++ < 50) {
      await new Promise((res) => setTimeout(res, 5));
    }
    if (globalInfo.refComponentPipelineCreate) {
      globalInfo.refComponentPipelineCreate.setPageStatus({
        setting: preSetting,
      });
    }
  } catch (err) {
    /** Ignore */
  }
}

export function closePagePipelineCreate() {
  const { refComponentPipelineCreate } = globalInfo;
  if (refComponentPipelineCreate) {
    refComponentPipelineCreate.setPageStatus({
      setting: undefined,
    });
  }
}

export interface IPipelineInfoFormated {
  uuid: IPipelineInfo['uuid'];
  viewTemplateId: IPipelineInfo['viewTemplateId'];
  name: IPipelineInfo['name'];
  customerInfos: IPipelineInfo['customer']['customerInfos'];
  modelName: IPipelineInfo['modelName'];
  destination: IPipelineInfo['properties']['sink']['type'];
  granularity: string;
  startTime: IPipelineInfo['properties']['scheduler']['startTime'];
  endTime: IPipelineInfo['properties']['scheduler']['endTime'];
  runStatistic: IPipelineInfo['runStatistic'];
  status: IPipelineInfo['status'];
  origin: IPipelineInfo;
}

/**
 * Get granularity info by viewTemplateId from dataModelList
 * NOTICE: useless if granularity property has exist in PipelineInfo
 */
export function updateFrequencyByTemplateInfo(
  pipelineList: {
    viewTemplateId: string;
    granularity: string;
  }[],
  dataModelList: IDataModelItem[],
) {
  const templateList: IDataModelTemplateInfo[] = toDataModelTemplateList(dataModelList);
  if (!Array.isArray(templateList)) {
    return;
  }
  pipelineList.forEach((it) => {
    if (it.granularity) {
      return;
    }
    const templateInfo = templateList.find((it) => it.viewTemplateId === it.viewTemplateId);
    if (templateInfo) {
      it.granularity = formatFreqency(templateInfo.dataGranularity);
    }
  });
}

/**
 * Format pipelineInfo for table show
 */
export function formatPipelineInfo(info: IPipelineInfo): IPipelineInfoFormated {
  const {
    uuid,
    viewTemplateId,
    name,
    properties: {
      sink: { type: destination },
      scheduler: { startTime, endTime },
    },
    customer: { customerInfos },
    modelName,
    granularity,
    runStatistic,
    status,
  } = info;
  return {
    uuid,
    viewTemplateId,
    name,
    customerInfos,
    modelName,
    destination,
    granularity: formatFreqency(granularity),
    startTime,
    endTime,
    runStatistic,
    status,
    origin: info,
  };
}

import React, { CSSProperties, useCallback, useMemo } from 'react';
import { Button, Input, Modal, Select } from 'antd';
import styles from './RuleMapper.module.less';
import { DefaultOptionType } from 'antd/lib/select';

export interface IConfigRuleItem {
  field?: string;
  condition: string;
  value: string;
  disabled?: boolean;
}

export type TConfigRule = IConfigRuleItem[][];

const RuleMapper: React.FC<{
  conditions: DefaultOptionType[];
  value: TConfigRule;
  showTitle?: boolean;
  width?: {
    condtion: number;
    value: number;
    action: number;
  };
  editable?: boolean;
  style?: CSSProperties;
  onChange?: (value: TConfigRule) => void;
  columnName: string;
}> = (props) => {
  const {
    value,
    conditions = [],
    editable = true,
    showTitle = true,
    style,
    onChange,
    width = {
      condtion: 120,
      value: 160,
      action: 60,
    },
    columnName,
  } = props;
  const getDefaultCondition = useCallback((): string => {
    if (!Array.isArray(conditions) || conditions.length === 0) {
      return '';
    }
    const { label, value } = conditions[0];
    return (value !== undefined ? value : label) as string;
  }, [conditions]);
  const notifyChange = useCallback(
    (value: TConfigRule) => {
      if (!onChange) {
        return;
      }
      onChange(value.slice());
    },
    [onChange],
  );
  const handleAddOrRule = useCallback(
    (andIndex: number) => {
      const newRule: IConfigRuleItem = {
        condition: getDefaultCondition(),
        value: '',
      };
      value[andIndex].push(newRule);
      const newValue = [...value];
      notifyChange(newValue);
    },
    [value, notifyChange, getDefaultCondition],
  );

  const handleRemoveOrRule = useCallback(
    (andIndex: number, orIndex: number) => {
      value[andIndex].splice(orIndex, 1);
      if (value[andIndex].length === 0) {
        value.splice(andIndex, 1);
      }
      const newValue = [...value];
      notifyChange(newValue);
    },
    [value, notifyChange],
  );

  const handleAddAndRule = useCallback(() => {
    const newRule: IConfigRuleItem[] = [
      {
        condition: getDefaultCondition(),
        value: '',
      },
    ];
    const newValue = [...value, newRule];
    notifyChange(newValue);
  }, [value, notifyChange, getDefaultCondition]);

  const rules = useMemo(() => {
    return value.map((item, andIndex) => {
      const ruleBlock = item.map((i, orIndex) => {
        const { disabled = !editable } = i;
        const rowKey = `${andIndex}_${orIndex}`;
        return (
          <div className={styles.row} key={rowKey}>
            <div
              className={styles.col}
              style={{
                width: `${width.condtion}px`,
                paddingRight: '10px',
              }}
            >
              <Select
                style={{
                  width: `${width.condtion}px`,
                }}
                size="small"
                disabled={disabled}
                value={i.condition}
                options={conditions}
                onChange={(v) => {
                  i.condition = v;
                  notifyChange(value);
                }}
              />
            </div>
            <div
              className={styles.col}
              style={{
                width: `${width.value}px`,
                paddingLeft: '4px',
              }}
            >
              <Input
                className={styles.textInput}
                size="small"
                disabled={disabled}
                value={i.value}
                onChange={(evt) => {
                  i.value = evt.target.value;
                  notifyChange(value);
                }}
              />
            </div>
            <div
              className={styles.col}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: `${width.action}px`,
              }}
            >
              <span
                className={`${styles.button} ${disabled ? styles.hidden : ''}`}
                style={{
                  color: 'red',
                }}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  handleRemoveOrRule(andIndex, orIndex);
                }}
              >
                x
              </span>
              <div
                className={`${orIndex === item.length - 1 ? styles.button : styles.buttonTextOnly} ${
                  disabled ? styles.disabled : ''
                }`}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  handleAddOrRule(andIndex);
                }}
              >
                Or
              </div>
            </div>
          </div>
        );
      });
      if (andIndex === value.length - 1) {
        return ruleBlock;
      }
      return (
        <React.Fragment key={`${andIndex}_and_label`}>
          {ruleBlock}
          <div
            style={{
              textAlign: 'center',
              margin: '4px 0px',
            }}
          >
            <span className={styles.andLabel}>AND</span>
          </div>
        </React.Fragment>
      );
    });
  }, [handleAddOrRule, handleRemoveOrRule, value, conditions, notifyChange, editable, width]);

  return (
    <div className={styles.wrapper} style={style ? style : {}}>
      {showTitle ? (
        <div
          className={styles.row}
          key="header"
          style={{
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '20px',
            color: '#949494',
            paddingBottom: '4px',
            borderBottom: '1px solid #E8E8E8',
          }}
        >
          <div className={styles.col}>Condition</div>
          <div className={styles.col}>Value</div>
        </div>
      ) : null}
      {rules}
      {editable ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className={value.length === 0 ? styles.addRuleButton : ''}
        >
          <Button
            onClick={handleAddAndRule}
            size="small"
            style={{
              display: value.length > 0 ? 'none' : 'inline-block',
            }}
          >
            {value.length === 0 ? 'Add Rule' : 'Add And Rule'}
          </Button>

          {value.length > 0 ? (
            <Button
              size="small"
              danger={true}
              style={{
                marginTop: '0px',
              }}
              onClick={async () => {
                const feedback = await new Promise<'ok' | 'cancel'>((res) => {
                  Modal.confirm({
                    width: 500,
                    content: `Are you sure to remove all rules for column ${columnName}?`,
                    onOk() {
                      res('ok');
                    },
                    onCancel() {
                      res('cancel');
                    },
                  });
                });
                if (feedback === 'ok') {
                  notifyChange([]);
                }
              }}
            >
              Remove All Rules
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default RuleMapper;

import ReactDOM from 'react-dom';
import './index.css';
import Daas from './App';
import NoAuth from '@/service/components/NoAuth';
import reportWebVitals from './reportWebVitals';
import {
  getBuildEnv,
  getBuildTarget,
  permission4ConvivaConnect,
  setAppHubProps,
  TBuildTarget,
} from './service/utils/env';
import { IAppHubProps } from './service/types';
import { startSentryMonitor, stopSentryMonitor } from './service/utils/sentry';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const appName = (getBuildEnv('appName') ? getBuildEnv('appName') : 'Conviva Connect') as string;
const useSentry = (['PROD', 'STG1'] as TBuildTarget[]).indexOf(getBuildTarget()) > -1;

const render = (props?: IAppHubProps) => {
  if (getBuildTarget() === 'STG1') {
    console.log(`${appName} render`);
    console.log(props);
  }
  const { container, user } = (props ? props : {}) as IAppHubProps;
  ReactDOM.render(
    permission4ConvivaConnect() ? <Daas /> : <NoAuth appName={appName} />,
    container ? container.querySelector('#root') : document.getElementById('root'),
  );
};

if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

export async function bootstrap() {
  console.log(`${appName} bootstraped`);
}

export async function mount(props: IAppHubProps) {
  if (getBuildTarget() === 'STG1') {
    console.log(`${appName} mount`);
    console.log(props);
    console.log(window.__POWERED_BY_QIANKUN__);
  }
  setAppHubProps(props);
  if (useSentry) {
    startSentryMonitor();
  }
  render(props);
}
// @ts-ignore
console.log(process.env);

export async function unmount() {
  const $app = document.getElementById('root');
  if ($app) {
    ReactDOM.unmountComponentAtNode($app);
  }
  if (useSentry) {
    stopSentryMonitor();
  }
}

export async function update(props: any) {
  console.log(`${appName} update props', prop`);
}

import { useEffect, useMemo, useState } from 'react';

interface IUsePreviewFileName {
  defaultPrefix?: string;
  defaultSuffix?: string;
  granularity?: string;
}

const usePreviewFileName = ({ defaultPrefix, defaultSuffix, granularity }: IUsePreviewFileName) => {
  const [suffix, setSuffix] = useState('');
  const [prefix, setPrefix] = useState('');

  const previewFileName = useMemo(() => {
    if (suffix !== '' && prefix !== '') {
      if (granularity === 'Hourly') {
        return `File Name: ${prefix}YYYY-MM-DD hh:mm:ss${suffix}`;
      }
      return `File Name: ${prefix}YYYY-MM-DD${suffix}`;
    } else if (suffix !== '' || prefix !== '') {
      if (granularity === 'Hourly') {
        return prefix !== ''
          ? `File Name: ${prefix}YYYY-MM-DD hh:mm:ss`
          : suffix !== ''
          ? `File Name: YYYY-MM-DD hh:mm:ss${suffix}`
          : '';
      }
      return prefix !== '' ? `File Name: ${prefix}YYYY-MM-DD` : suffix !== '' ? `File Name: YYYY-MM-DD${suffix}` : '';
    } else {
      return '';
    }
  }, [suffix, prefix, granularity]);

  useEffect(() => {
    if (defaultPrefix) {
      setPrefix(defaultPrefix);
    }
    if (defaultSuffix) {
      setSuffix(defaultSuffix);
    }
  }, [defaultPrefix, defaultSuffix]);

  return {
    suffix,
    setSuffix,
    prefix,
    setPrefix,
    previewFileName,
  };
};

export default usePreviewFileName;

import type { TokensToRegexpOptions, Key } from 'path-to-regexp';
import { pathToRegexp } from 'path-to-regexp';

export default function pathMatch(options: TokensToRegexpOptions) {
  options = options || {};

  return function (path: string) {
    var keys: Key[] = [];
    var re = pathToRegexp(path, keys, options);

    return function (pathname: string, params?: { [key: string]: string }) {
      var m = re.exec(pathname);
      if (!m) return false;

      params = params || {};

      var key, param;
      for (var i = 0; i < keys.length; i++) {
        key = keys[i];
        param = m[i + 1];
        if (!param) continue;
        params[key.name] = decodeURIComponent(param);
      }

      return params;
    };
  };
}

import { TSinkType } from '@/service/types';

export const SUPPORT_SINGLE_FILE_FORMAT_NAME = ['csv', 'csv.gz'];
export const STEPS = ['Select Data Model', 'Select Data Destination', 'Configure Data'];
export const AUTH_RELATED_PROPS: {
  [key in TSinkType]: string[];
} = {
  GCS: ['project', 'path', 'serviceAccountJSON'],
  BigQuery: ['project', 'dataset', 'table', 'partitionType', 'partitionByField', 'serviceAccountJSON'],
  S3: ['path', 'accessID', 'accessKey'],
  Snowflake: ['accountName', 'database', 'schemaName', 'tableName', 'username', 'password', 'keyPairEnabled'],
  SFTP: ['path', 'username', 'password', 'privateKey', 'host', 'port'],
  'S3-iam': ['path', 'roleArn', 'externalId', 'awsRegion'],
};

import { useQuery } from 'react-query';
import { EReactQueryKey } from '../utils/common';
import { requestData } from './api';

export interface ISessionTags {
  modelId: string;
  customerIds: string;
}

export async function requestSessionTags(config: { urlParams: ISessionTags }) {
  return await requestData({
    method: 'get',
    url: `/v1.0/models/${config.urlParams.modelId}/tags/${config.urlParams.customerIds}`,
    showErrorMessage: true,
    timeoutErrorMessage: 'request session tags list fail',
  });
}

export function useRequestSessionTags(urlParams: ISessionTags) {
  return useQuery([EReactQueryKey.SESSION_TAGS, urlParams.modelId, urlParams.customerIds], async () => {
    if (urlParams.modelId !== '' && urlParams.customerIds !== '') {
      return await requestSessionTags({ urlParams });
    }
    return null;
  });
}

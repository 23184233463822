import React, { useMemo } from 'react';
import { BrowserRouter, Route, Routes, PathRouteProps, useLocation, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getBaseUrl, getRelativePath } from '@/service/utils/env';
import DataModelList from '@/pages/data-model/list';
import DataModelItem from '@/pages/data-model/item';
import PipelineList from '@/pages/pipeline/list';
import PipelineCreate, { IRefPipelineCreate } from '@/pages/pipeline/create';
import PipelineEdit, { IRefPipelineEdit } from '@/pages/pipeline/edit';
import { EPath, DYNAMIC_NAV_LIST, ENavLabel } from '@/service/utils/common';
import KeepAlive from '@/service/components/KeepAlive';
import { pathToRegexp } from 'path-to-regexp';
import { globalInfo } from '@/service/store';
import { ConfigProvider } from 'antd';
import NavWrapper from './service/components/NavWrapper';
import 'antd/dist/antd.css';
import '@/service/style/antd-fix.less';
import './App.less';

ConfigProvider.config({
  prefixCls: 'daas-ant',
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: false,
    },
  },
});

export const routes: PathRouteProps[] = [
  {
    path: EPath.DATA_MODEL_LIST,
    element: <DataModelList />,
  },
  {
    path: EPath.DATA_MODEL_ITEM_WITH_MODEL_ID_ONLY,
    element: <DataModelItem />,
  },
  {
    path: EPath.DATA_MODEL_DETAIL,
    element: <DataModelItem />,
  },
  {
    path: EPath.PIPELINE_LIST,
    element: <PipelineList />,
  },
  {
    path: EPath.PIPELINE_CREATE,
    element: <PipelineCreate />,
  },
  {
    path: EPath.PIPELINE_EDIT,
    element: <PipelineEdit />,
  },
];
const routesRegList = routes.map((it) => pathToRegexp(it.path));

function handleBeforeUnload(evt: BeforeUnloadEvent) {
  evt.preventDefault();
  const href = window.location.pathname;
  const pathname = getRelativePath(href);
  const dynamicNav = DYNAMIC_NAV_LIST.find((it) => it.isActive({ pathname }));
  if (!dynamicNav) {
    return;
  }
  if ([ENavLabel.PIPELINE_CREATE, ENavLabel.PIPELINE_EDIT].indexOf(dynamicNav.label as ENavLabel) > -1) {
    evt.returnValue = 'A pipeline is in editing';
  }
}
window.onbeforeunload = handleBeforeUnload;
window.onunload = handleBeforeUnload;

const App: React.FC<{}> = () => {
  const filteredRoute = routes.filter(
    (it) => [EPath.PIPELINE_CREATE, EPath.PIPELINE_EDIT].indexOf(it.path as EPath) === -1,
  );
  return (
    <div className="app">
      <ConfigProvider prefixCls="daas-ant">
        <NavWrapper />
        <Routes>
          {filteredRoute.map((it) => {
            return <Route {...it} key={it.path} />;
          })}
        </Routes>
        <AliveComponents />
        <DefaultPage />
      </ConfigProvider>
    </div>
  );
};

const AliveComponents: React.FC<{}> = () => {
  const location = useLocation();
  const navItem = DYNAMIC_NAV_LIST.find((it) => it.isActive(location));
  let Comp: React.ReactNode | null = null;
  const components: {
    [EPath.PIPELINE_CREATE]: React.ReactNode;
    [EPath.PIPELINE_EDIT]: React.ReactNode;
  } = useMemo(() => {
    return {
      [EPath.PIPELINE_CREATE]: (
        <PipelineCreate
          ref={(instance: IRefPipelineCreate) => {
            globalInfo.refComponentPipelineCreate = instance;
          }}
        />
      ),
      [EPath.PIPELINE_EDIT]: (
        <PipelineEdit
          ref={(instance: IRefPipelineEdit) => {
            globalInfo.refComponentPipelineEdit = instance;
          }}
        />
      ),
    };
  }, []);
  if (navItem) {
    Comp = components[navItem.path as keyof typeof components];
  }

  const activeName = navItem && Comp ? navItem.path : '';
  return (
    <KeepAlive activeName={activeName} include={DYNAMIC_NAV_LIST.map((it) => it.path)}>
      {Comp}
    </KeepAlive>
  );
};

function DefaultPage() {
  const { pathname } = useLocation();
  const matched = routesRegList.some((it) => it.test(pathname));
  if (matched) {
    return null;
  }
  return <Navigate to={EPath.DATA_MODEL_LIST} />;
}

const Entry: React.FC<{}> = () => {
  return (
    <React.StrictMode>
      <BrowserRouter basename={getBaseUrl()}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};
export default Entry;

import { IRequestConfig, requestData } from './api';
import { IPipelinePayload, IPipelieInfoByPage, IPipelineInfo, TSinkType } from '@/service/types/pipeline';
import { useQuery } from 'react-query';

export async function requestStatistic(): Promise<{
  activeCount: number;
  inActiveCount: number;
}> {
  return requestData({
    ...{
      method: 'get',
      url: '/v1.0/statistics',
    },
  });
}

export function useRequestStatistic(config = {}) {
  return useQuery(
    ['statistic'],
    async () => {
      return await requestStatistic();
    },
    config,
  );
}

export function useQueryPipelineList(params: IPipelineListParams) {
  const { pageSize, pageNum, modelUuid, latestNDays, accounts, pipelineName, createUser, status } = params;
  return useQuery(
    ['pipelineList', pageSize, pageNum, modelUuid, latestNDays, accounts, pipelineName, createUser, status],
    async () => {
      return await requestPipelineList({ params });
    },
  );
}

export async function requestTestConnection(config: { data: IPipelinePayload['properties']['sink'] }) {
  return requestData({
    ...{ method: 'post', url: '/v1.0/connections/check', replaceErrorMessage: true },
    ...config,
  });
}

export async function requestCreatePipeline(
  config: { data: IPipelinePayload } & IRequestConfig,
): Promise<IPipelinePayload> {
  return requestData({
    ...{ method: 'post', url: '/v1.0/pipelines' },
    ...config,
  });
}

export async function requestUpdatePipeline(
  config: {
    data: IPipelinePayload;
    urlParams: {
      uid: string;
    };
  } & IRequestConfig,
) {
  return requestData({
    ...{ method: 'put', url: '/v1.0/pipelines/:uid' },
    ...config,
  });
}

export async function requestPatchPipelie(
  config: {
    urlParams: {
      uid: string;
    };
    params: {
      to_pause: 'true' | 'false';
    };
  } & IRequestConfig,
) {
  return requestData({
    ...{ method: 'patch', url: '/v1.0/pipelines/:uid', showErrorMessage: true },
    ...config,
  });
}

export interface IPipelineListParams {
  /** condition */
  pageSize?: number;
  pageNum?: number;
  modelUuid?: string;
  latestNDays?: string;
  /** for column search */
  accounts?: string;
  pipelineName?: string;
  createUser?: string;
  status?: string;
}

export async function requestPipelineList(config?: { params?: IPipelineListParams }): Promise<IPipelieInfoByPage> {
  const data = (await requestData({
    ...{
      method: 'get',
      url: '/v1.0/pipelines',
      timeoutErrorMessage: 'request pipeline list fail',
    },
    ...config,
  })) as IPipelieInfoByPage;
  data.entities.forEach((it) => {
    try {
      if (it.properties.sink.type) {
        it.properties.sink.type = patch4SinkType(it.properties.sink.type);
      }
    } catch (err) {
      /** Ignore */
    }
  });
  return data;
}

export async function requestPipelineDetail(config: {
  urlParams: {
    uuid: string;
  };
}): Promise<IPipelineInfo> {
  const data = (await requestData({
    ...{ method: 'get', url: '/v1.0/pipelines/{uuid}', showErrorMessage: true },
    ...config,
  })) as IPipelineInfo;
  try {
    if (data.properties.sink.type) {
      data.properties.sink.type = patch4SinkType(data.properties.sink.type);
    }
  } catch (err) {
    /** Ignore */
  }
  return data;
}

// 'get /v1.0/pipelines/{uuid}/runs': {
//   request: {
//     urlParams: {
//       uuid: string;
//     };
//   };
//   responseData: IResponseData<IPipelineRunsItem[]>;
// };
type TExtendedSinkType = TSinkType | 'SparkS3' | 'SparkGCS';
const sinkTypeMap: {
  [t in TExtendedSinkType]: TSinkType;
} = {
  GCS: 'GCS',
  S3: 'S3',
  BigQuery: 'BigQuery',
  Snowflake: 'Snowflake',
  SparkGCS: 'GCS',
  SparkS3: 'S3',
  SFTP: 'SFTP',
  'S3-iam': 'S3-iam',
};

export function patch4SinkType(sinkType: TExtendedSinkType): TSinkType {
  if (sinkType in sinkTypeMap) {
    return sinkTypeMap[sinkType];
  }
  return sinkType as TSinkType;
}

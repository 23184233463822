import { useQuery } from 'react-query';
import { EReactQueryKey } from '../utils/common';
import { requestData } from './api';

export interface IAccount {
  id: string;
  name: string;
  tzName: string;
}
export async function requestAccounts(uuid?: string): Promise<IAccount[]> {
  let url = '/v1.0/accounts';
  if (uuid) {
    url = `/v1.0/accounts/${uuid}`;
  }
  const data = (await requestData({
    ...{
      method: 'get',
      url,
      showErrorMessage: true,
      timeoutErrorMessage: 'request account list fail',
    },
  })) as {
    customerInfos: IAccount[];
  };
  return data.customerInfos;
}

export function useRequestAccounts(uuid?: string) {
  return useQuery(
    [EReactQueryKey.ACCOUNT, uuid],
    async () => {
      return await requestAccounts(uuid);
    },
    {
      enabled: !!uuid,
    },
  );
}

import styled from 'styled-components';

const ContentWrapper = styled.div`
  height: calc(100vh - 55px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  opacity: 0.9;
  box-shadow: 6px 0px 5px rgb(0 0 0 / 20%);
  overflow-x: hidden;
`;

const Text = styled.p`
  font-size: 24px;
  width: 60%;
  margin: 0 auto;
  font-family: Open Sans, sans-serif;
`;

const ButtonWrapper = styled.span`
  display: block;
  text-align: center;
  margin: 1em;
`;

const Button = styled.button`
  display: inline-block;
  outline: none;
  box-sizing: border-box;
  text-align: center;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  height: 34px;
  padding: 6px 10px;
  cursor: pointer;
  color: #0046ff;
  background-color: #ffffff;
  border-color: #0046ff;
`;

const contactConviva = () => {
  window.open('/app/pulse/support', '_blank');
};

const NoAccess: React.FC<{
  appName: string;
}> = (props) => {
  const { appName } = props;
  return (
    <ContentWrapper id="no-access">
      <Text>
        {appName} is not included in your company’s product packaging. <br />
        Contact your Conviva representative for more information.
        <ButtonWrapper>
          <Button onClick={contactConviva}>Contact Conviva</Button>
        </ButtonWrapper>
      </Text>
    </ContentWrapper>
  );
};

export default NoAccess;

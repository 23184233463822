import { IDataModelTemplateInfo, IDataModelTemplateKey, IDataModelItem, IDataModelVersionInfo } from '@/service/types';
import { DefaultOptionType } from 'antd/lib/cascader';
import { constants } from './common';
import { IDataModelGranularity } from '@/service/types/dto';

/**
 * Get dataModel Info by viewTemplateId
 */
export function getDataModelTemplateInfo(
  viewTemplateId: string,
  dataModelList: IDataModelItem[],
): IDataModelTemplateInfo | null {
  if (!Array.isArray(dataModelList) || !viewTemplateId) {
    return null;
  }
  for (let dataModel of dataModelList) {
    for (let version of dataModel.versions) {
      for (let granularity of version.granularities) {
        if (granularity.viewTemplateId === viewTemplateId) {
          return { ...dataModel, ...version, ...granularity, modelId: dataModel.uuid };
        }
      }
    }
  }
  return null;
}
export function getDataModelVersionInfo(
  templateKey?: Pick<IDataModelTemplateKey, 'modelId' | 'version'>,
  dataModelList?: IDataModelItem[],
): IDataModelVersionInfo | null {
  if (!Array.isArray(dataModelList) || !templateKey) {
    return null;
  }
  for (let dataModel of dataModelList) {
    if (dataModel.uuid === templateKey.modelId) {
      for (let version of dataModel.versions) {
        if (version.version === templateKey.version) {
          return { ...dataModel, ...version };
        }
      }
    }
  }
  return null;
}
export function toDataModelTemplateList(dataModelList?: IDataModelItem[]): IDataModelTemplateInfo[] {
  const res: IDataModelTemplateInfo[] = [];
  if (!Array.isArray(dataModelList)) {
    return res;
  }
  for (let dataModel of dataModelList) {
    for (let version of dataModel.versions) {
      for (let granularity of version.granularities) {
        res.push({ ...dataModel, ...version, ...granularity, modelId: dataModel.uuid });
      }
    }
  }
  return res;
}
export function getTemplateKey(dataModel: IDataModelItem): IDataModelTemplateKey {
  const templateKey: IDataModelTemplateKey = {
    modelId: constants.EMPTY_MODEL_ID,
    version: constants.EMPTY_VERSIN,
    viewTemplateId: constants.EMPTY_TEMPLATE_ID,
  };
  const { uuid: modelId, versions } = dataModel;
  templateKey.modelId = modelId;
  if (!Array.isArray(versions) || versions.length === 0) {
    return templateKey;
  }
  const [versionInfo] = versions;
  const { version, granularities } = versionInfo;
  templateKey.version = version;
  if (!Array.isArray(granularities) || granularities.length === 0) {
    return templateKey;
  }
  const [{ viewTemplateId }] = granularities;
  templateKey.viewTemplateId = viewTemplateId;
  return templateKey;
}
export function formatFreqency(frequency: IDataModelGranularity['dataGranularity']) {
  const frequencyMap: {
    [key in IDataModelGranularity['dataGranularity']]: 'Hourly' | 'Daily';
  } = {
    HOUR: 'Hourly',
    DAY: 'Daily',
  };
  return frequencyMap[frequency] ? frequencyMap[frequency] : frequency;
}

export function dataModelListToCascaderOptions(
  dataModelList?: IDataModelItem[],
  viewTemplateId?: string,
): DefaultOptionType[] {
  if (!Array.isArray(dataModelList)) {
    return [];
  }
  let templateInfo: IDataModelTemplateInfo | null = null;
  if (viewTemplateId) {
    templateInfo = getDataModelTemplateInfo(viewTemplateId, dataModelList);
  }
  return dataModelList.map((it) => {
    const { versions, displayName, uuid } = it;
    return {
      label: displayName,
      value: uuid,
      disabled: Boolean(templateInfo && templateInfo.uuid !== uuid),
      children: versions.map((it) => {
        return {
          label: it.version,
          value: it.version,
        };
      }),
    };
  });
}

/**
 * check if modelId existed in modelList
 * @param modelId
 * @param modelList
 * @returns modelId checked
 */
export function checkModelId(modelId: string, modelList?: IDataModelItem[]): string {
  if (!Array.isArray(modelList) || modelList.length === 0) {
    return modelId;
  }
  const target = modelList.find((it) => it.uuid === modelId);
  if (target) {
    return modelId;
  }
  return modelList[0].uuid;
}
/**
 * make sure viewTemplate is exist
 * @param template
 * @return if @param template exist return it, else return the first template in dataModelList
 */
export function checkViewTemplate(
  template: IDataModelTemplateKey,
  dataModelList: IDataModelItem[],
): IDataModelTemplateKey {
  if (!Array.isArray(dataModelList) || dataModelList.length === 0) {
    return template;
  }
  const { viewTemplateId } = template;
  const info = getDataModelTemplateInfo(viewTemplateId, dataModelList);
  if (!info) {
    const dataModel = dataModelList[0];
    const {
      versions: [versionItem],
      uuid: modelId,
    } = dataModel;
    const {
      version,
      granularities: [{ viewTemplateId }],
    } = versionItem;
    return {
      modelId,
      version,
      viewTemplateId,
    };
  }
  if (info.modelId === template.modelId && info.version === template.version) {
    return template;
  }
  return info;
}

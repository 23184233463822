import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IPipelineSetting } from '@/service/types';
import { IConnectionTestStatus } from '@/pages/pipeline/components/Setting/typed';
import { FormInstance } from 'rc-field-form';
import usePreviewFileName from '@/pages/pipeline/components/Setting/customHooks/usePreviewFileName';
import { SUPPORT_SINGLE_FILE_FORMAT_NAME } from '@/pages/pipeline/components/Setting/contant';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { EPartFile, FILE_FORMAT_LIST } from '@/service/utils/pipeline';
import FileFormatWrapper from '@/pages/pipeline/components/Setting/FileFormatWrapper';
import classNames from 'classnames';
import TextArea from 'antd/lib/input/TextArea';

interface ISettingSFTPProps {
  setting: Partial<IPipelineSetting> | null;
  onConnectionTest: (opt?: { showSuccess: boolean }) => void;
  status: IConnectionTestStatus;
  form: FormInstance<IPipelineSetting>;
  isEdit: boolean;
  granularity: string;
  sameKeyForSftp: boolean;
  changeCredentialKeyForSftp: (value: string) => void;
  showEncrypt: boolean;
  showCredential: () => void;
  encryptCredential: () => void;
  cancelModifyEncryptCredential: () => void;
}

const SettingSFTPProps: React.FC<ISettingSFTPProps> = (props) => {
  const {
    setting,
    onConnectionTest,
    status: { requesting, errorMessage },
    form,
    isEdit,
    granularity,
    sameKeyForSftp,
    changeCredentialKeyForSftp,
    showEncrypt,
    showCredential,
    encryptCredential,
    cancelModifyEncryptCredential,
  } = props;
  const formValue = form.getFieldsValue();
  const sinkType = formValue?.sinkType;
  const properties = form.getFieldValue(['sink', 'properties']);

  const [passwordOrPemLabel, setPasswordOrPemLabel] = useState('password');
  const [encrypt, setEncrypt] = useState<boolean>(true);
  // This useState is used to update the component
  const [formatValue, setFormatValue] = useState<string>();

  const { setSuffix, setPrefix, previewFileName } = usePreviewFileName({
    defaultPrefix: properties?.prefix,
    defaultSuffix: properties?.suffix,
    granularity: granularity,
  });

  const isShowSingleFile = useMemo(() => {
    return SUPPORT_SINGLE_FILE_FORMAT_NAME.includes(properties?.format);
  }, [properties?.format, formatValue]);

  useEffect(() => {
    const privateKey = setting?.sink?.properties?.privateKey;
    if (sameKeyForSftp) {
      if (privateKey || privateKey === '') {
        setPasswordOrPemLabel('privateKey');
      } else {
        setPasswordOrPemLabel('password');
      }
    }
  }, []);

  useEffect(() => {
    !isEdit && setEncrypt(false);
  }, [isEdit]);

  const selectChange = (value: any, option: any) => {
    setPasswordOrPemLabel(value);
    changeCredentialKeyForSftp(value);
  };

  const onEdit = useCallback(() => {
    setEncrypt((encrypt) => !encrypt);
    showCredential();
  }, [showCredential]);

  const onSave = useCallback(() => {
    setEncrypt((encrypt) => !encrypt);
    encryptCredential();
  }, [encryptCredential]);

  const onCancel = useCallback(() => {
    setEncrypt((encrypt) => !encrypt);
    cancelModifyEncryptCredential();
  }, [cancelModifyEncryptCredential]);

  return (
    <>
      <Form.Item
        key={['sink', 'properties', 'format'].join('')}
        name={['sink', 'properties', 'format']}
        label="File Format"
        initialValue={FILE_FORMAT_LIST[0]}
        rules={[
          {
            required: true,
          },
          () => ({
            validator(_, value) {
              setFormatValue(value);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <FileFormatWrapper />
      </Form.Item>
      {isShowSingleFile && (
        <Form.Item
          key={['sink', 'properties', 'partNum'].join('')}
          name={['sink', 'properties', 'partNum']}
          label="Single File"
          tooltip="Delivered as one file"
          initialValue={EPartFile.DISABLE}
          getValueProps={(v: string) => {
            const checked = v !== EPartFile.DISABLE;
            return {
              checked,
              value: checked,
            };
          }}
          getValueFromEvent={(evt) => {
            try {
              return evt.target.checked ? EPartFile.ENABLE : EPartFile.DISABLE;
            } catch (err) {
              return EPartFile.DISABLE;
            }
          }}
        >
          <Checkbox>enabled</Checkbox>
        </Form.Item>
      )}
      <Form.Item
        key={['sink', 'properties', 'prefix'].join('')}
        name={['sink', 'properties', 'prefix']}
        label="File Name Prefix"
        initialValue={''}
        rules={[
          {
            required: false,
          },
          () => ({
            validator(_, value) {
              setPrefix(value);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        key={['sink', 'properties', 'suffix'].join('')}
        name={['sink', 'properties', 'suffix']}
        label="File Name Suffix"
        initialValue={''}
        rules={[
          {
            required: false,
          },
          () => ({
            validator(_, value) {
              setSuffix(value);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
      <span style={{ color: 'rgba(0,0,0,0.45)' }}>{previewFileName}</span>
      <div className={classNames(['category', errorMessage ? 'error' : ''])}>
        <div className="header">
          <span className="title">{`Authentication for Sink ${sinkType ? sinkType : ''}`}</span>
          <Button
            loading={requesting}
            type="link"
            size="small"
            onClick={(evt) => {
              evt.stopPropagation();
              onConnectionTest({
                showSuccess: true,
              });
            }}
          >
            Test Connection
          </Button>
        </div>
        <Form.Item
          key={['sink', 'properties', 'path'].join('')}
          name={['sink', 'properties', 'path']}
          label="Path"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `path is required for SFTP sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'username'].join('')}
          name={['sink', 'properties', 'username']}
          label="username"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `username is required for SFTP sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Select
              className={classNames(['required', 'password-privateKey-select'])}
              onChange={selectChange}
              value={passwordOrPemLabel}
              options={[
                {
                  value: 'password',
                  label: 'password',
                },
                {
                  value: 'privateKey',
                  label: 'private key',
                },
              ]}
            />
            {passwordOrPemLabel === 'password' ? (
              <Form.Item
                key={['sink', 'properties', 'password'].join('')}
                name={['sink', 'properties', 'password']}
                required
                style={{ width: '600px' }}
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: `password is required for SFTP sink`,
                  },
                ]}
              >
                <Input disabled={showEncrypt && encrypt && sameKeyForSftp} />
              </Form.Item>
            ) : (
              <Form.Item
                key={['sink', 'properties', 'privateKey'].join('')}
                name={['sink', 'properties', 'privateKey']}
                required
                style={{ width: '600px' }}
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: `privateKey is required for SFTP sink`,
                  },
                ]}
              >
                <TextArea rows={3} disabled={showEncrypt && encrypt && sameKeyForSftp} />
              </Form.Item>
            )}
          </div>
          {isEdit && showEncrypt && sameKeyForSftp && (
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                right: '-160px',
                top: 0,
                gap: '10px',
              }}
            >
              {encrypt ? (
                <>
                  <Button onClick={onEdit}>Edit</Button>
                  <div style={{ width: '76px' }} />
                </>
              ) : (
                <>
                  <Button onClick={onSave}>Save</Button>
                  <Button onClick={onCancel}>Cancel</Button>
                </>
              )}
            </div>
          )}
        </div>
        <Form.Item
          key={['sink', 'properties', 'host'].join('')}
          name={['sink', 'properties', 'host']}
          label="host"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `host is required for SFTP sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'port'].join('')}
          name={['sink', 'properties', 'port']}
          label="port"
          initialValue="22"
          rules={[
            {
              type: 'string',
              required: false,
              message: `port is required for SFTP sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {errorMessage ? <div className="error-message">{errorMessage}</div> : null}
      </div>
    </>
  );
};

export default SettingSFTPProps;

import { useSetState, useUpdate } from 'ahooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IPipelineListParams, requestPatchPipelie, useQueryPipelineList } from '@/service/request/pipeline';
import { formatDate } from '../libs/date';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { IPipelineInfo } from '../types';
import { Button, message, Table } from 'antd';
import { antdPaginationConfig, DEFAULT } from '@/service/utils/common';
import { formatPipelineInfo, IPipelineInfoFormated, toPagePipelineEdit } from '@/service/utils/pipeline';

export type TPipelineListColumn =
  | 'Pipeline Name'
  | 'Accounts'
  | 'Data'
  | 'Destination'
  | 'Frequency & Duration'
  | 'Runs in last 14 days'
  | 'Status';

export interface IRefTablePipelineList {
  refreshData: () => void;
}

interface IPageStatus {
  requestingPauseUidList: string[];
}

export const TablePipelineList = React.forwardRef<
  IRefTablePipelineList,
  {
    params?: IPipelineListParams;
    excludeColumns?: TPipelineListColumn[];
    onStatusChange?: (status: Pick<IPipelineListParams, 'pageNum' | 'pageSize'>) => void;
    onActiveStatusChange?: (info: IPipelineInfo) => void;
  }
>((props, ref) => {
  const { params: preParams = {}, excludeColumns = [], onStatusChange, onActiveStatusChange } = props;
  const { pageNum = 1, pageSize = DEFAULT.pageSize, modelUuid } = preParams;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const forceRender = useUpdate();
  const [pageStatus, setPageStatus] = useSetState<IPageStatus>({
    requestingPauseUidList: [],
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: pageNum,
    pageSize,
    total: 0,
    ...antdPaginationConfig,
  });
  const [pipelineListPreParams, setPipelineListPreParams] = useState<IPipelineListParams>({
    pageNum,
    pageSize,
    modelUuid,
  });
  const { data: pipelineListData, isLoading, refetch } = useQueryPipelineList(pipelineListPreParams);

  const pipelineList = useMemo(() => {
    return pipelineListData?.entities.map((it) => {
      return formatPipelineInfo(it);
    });
  }, [pipelineListData?.entities]);

  React.useImperativeHandle(ref, () => ({
    refreshData() {
      if (!pipelineListData || !pipelineListPreParams) {
        return;
      }
      const { pageNum = 1 } = pipelineListPreParams;
      // refresh only current is 1
      if (pageNum === 1) {
        refetch();
      }
    },
  }));
  useEffect(() => {
    pipelineListData &&
      setPagination((p) => ({
        ...p,
        total: pipelineListData?.total,
      }));
  }, [pipelineListData]);
  // update frequency on dataModelList change
  // useEffect(() => {
  //   if (dataModelList) {
  //     const { dataSource } = tableProps;
  //     updateFrequencyByTemplateInfo(dataSource, dataModelList);
  //     forceRender();
  //   }
  // }, [dataModelList]);

  const tableRef = useRef<HTMLDivElement>(null);

  const columns: ColumnsType<IPipelineInfoFormated> = useMemo(() => {
    return (
      [
        {
          title: 'Pipeline Name',
          dataIndex: ['name'],
        },
        {
          title: 'Accounts',
          dataIndex: 'customerInfos',
          render(
            v: {
              name: string;
              id: string;
            }[],
          ) {
            if (Array.isArray(v)) {
              // return v.map(c => (
              //   <div>
              //     {c.name}({c.id})
              //   </div>
              // ));
              return v.map((c) => c.name).join(', ');
            }
            return '';
          },
        },
        {
          title: 'Data',
          dataIndex: 'modelName',
        },
        {
          title: 'Destination',
          dataIndex: 'destination',
        },
        {
          title: 'Frequency & Duration',
          render(_v, record) {
            const { granularity, startTime, endTime } = record;
            const formattedStartTime = formatDate(startTime, 'MM/d/yy');
            const formattedEndTime = formatDate(endTime, 'MM/d/yy');
            return (
              <>
                <div
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {granularity}
                </div>
                <div>
                  {formattedStartTime ? formattedStartTime : '---'} until {formattedEndTime ? formattedEndTime : '---'}
                </div>
              </>
            );
          },
        },
        {
          title: 'Runs in last 14 days',
          render(_v, it) {
            const {
              runStatistic: { successCount, failCount, runningCount },
            } = it;
            const totalRuns = successCount + failCount + runningCount;
            if (totalRuns === 0) {
              return '---';
            }
            return [
              successCount ? (
                <div
                  style={{
                    color: '#46B04A',
                    fontWeight: '600',
                  }}
                >
                  {successCount} Success
                </div>
              ) : null,
              failCount ? (
                <div
                  style={{
                    color: '#CF0000',
                    fontWeight: '600',
                  }}
                >
                  {failCount} Error
                </div>
              ) : null,
              runningCount ? (
                <div
                  style={{
                    color: '#00ff00',
                    fontWeight: '600',
                  }}
                >
                  {runningCount} Running
                </div>
              ) : null,
            ];
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render(_v, it) {
            const { status } = it;
            return (
              {
                UNPAUSED: 'Active',
                PAUSED: 'Paused',
              } as {
                [key in IPipelineInfo['status']]: string;
              }
            )[status as IPipelineInfo['status']];
          },
        },
        {
          title: 'Action',
          dataIndex: 'action',
          render(_v, it) {
            const {
              origin: { hasEditPermission },
            } = it;
            const { status, uuid, name } = it;
            return (
              <div>
                <Button
                  disabled={!hasEditPermission}
                  type="link"
                  onClick={(evt) => {
                    const { uuid } = it;
                    toPagePipelineEdit(uuid, navigate);
                  }}
                >
                  Edit
                </Button>
                <Button
                  disabled={!hasEditPermission}
                  type="link"
                  loading={pageStatus.requestingPauseUidList.indexOf(uuid) > -1}
                  onClick={async (evt) => {
                    evt.stopPropagation();
                    try {
                      const { requestingPauseUidList: requestPauseUidList } = pageStatus;
                      requestPauseUidList.push(uuid);
                      setPageStatus({
                        requestingPauseUidList: requestPauseUidList,
                      });
                      const toPause = status !== 'PAUSED';
                      await requestPatchPipelie({
                        urlParams: {
                          uid: uuid,
                        },
                        params: {
                          to_pause: (toPause ? 'true' : 'false') as 'true' | 'false',
                        },
                      });
                      message.success(`pipeline '${name}' has been ${toPause ? 'paused' : 'resumed'}`);
                      it.status = toPause ? 'PAUSED' : 'UNPAUSED';
                      if (onActiveStatusChange) {
                        onActiveStatusChange(it.origin);
                      }
                    } finally {
                      await queryClient.invalidateQueries({ queryKey: ['statistic'] });
                      await queryClient.invalidateQueries({ queryKey: ['pipelineList'] });
                      const { requestingPauseUidList: requestPauseUidList } = pageStatus;
                      const index = requestPauseUidList.indexOf(uuid);
                      if (index > -1) {
                        requestPauseUidList.splice(index, 1);
                        setPageStatus({
                          requestingPauseUidList: requestPauseUidList,
                        });
                      }
                    }
                  }}
                >
                  {status === 'PAUSED' ? 'Resume' : 'Pause'}
                </Button>
              </div>
            );
          },
        },
      ] as ColumnsType<IPipelineInfoFormated>
    ).filter((it) => {
      return excludeColumns.indexOf(it.title as TPipelineListColumn) === -1;
    });
  }, [preParams, pageStatus, excludeColumns]);

  const tableChange = (pagination: TablePaginationConfig) => {
    const { current, pageSize } = pagination;
    onStatusChange &&
      onStatusChange({
        pageNum: current,
        pageSize,
      });
    const params: IPipelineListParams = {
      pageNum: current,
      pageSize,
    };
    if (modelUuid) {
      params.modelUuid = modelUuid;
    }
    setPagination(pagination);
    setPipelineListPreParams(params);
  };

  return (
    <Table
      loading={isLoading}
      columns={columns}
      rowKey="uuid"
      ref={tableRef}
      sticky={true}
      onChange={tableChange}
      dataSource={pipelineList}
      pagination={pagination}
    />
  );
});

import React from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import './index.less';

export interface INavItem {
  /** full url */
  href?: string;
  /** for navigate by react-router-dom, href = baseUrl + path */
  path: string;
  label: string;
  isActive: (location: { pathname: string }) => boolean;
  closeable?: boolean;
}

const NavBar: React.FC<{
  navList: INavItem[];
  onClose?: (it: INavItem, index: number) => void;
  onClick?: (it: INavItem, index: number, location: Location) => void;
}> = (props) => {
  const { navList, onClose, onClick } = props;
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="nav-list">
      {navList.map((it, index) => {
        const { closeable, isActive, href, path } = it;
        const classList = ['nav-item'];
        if (closeable) {
          classList.push('closeable');
        }
        if (isActive(location)) {
          classList.push('active');
        }
        return (
          <a
            key={index}
            className={classList.join(' ')}
            href={href ? href : path}
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              navigate(path);
              onClick && onClick(it, index, location);
            }}
          >
            {it.label}
            {closeable && (
              <span
                className="close-icon"
                onClick={(evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                  onClose && onClose(it, index);
                }}
              >
                x
              </span>
            )}
          </a>
        );
      })}
    </div>
  );
};

export default NavBar;

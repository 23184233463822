import { observer } from 'mobx-react';
import { common } from '@/service/store';
import NavBar from '@/service/components/navbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { DYNAMIC_NAV_LIST, ENavLabel, EPath } from '@/service/utils/common';
import { getRelativePath } from '@/service/utils/env';
import { Modal } from 'antd';
import { closePagePipelineCreate, closePagePipelineEdit } from '../utils/pipeline';

/**
 * watch location change, dynamic add/remove navbar for New Pipeline or Edit Pipeline
 * @returns
 */
const NavWrapper: React.FC<{}> = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navList } = common;
  useEffect(() => {
    const dynamicNav = DYNAMIC_NAV_LIST.find((it) => it.isActive(location));
    if (dynamicNav && navList.every((it) => !it.isActive(location))) {
      let { href, path } = dynamicNav;
      if (dynamicNav.label === 'Edit Pipeline') {
        href = window.location.pathname;
        path = getRelativePath(href);
      }
      navList.push({
        path,
        href,
        label: dynamicNav.label,
        isActive: dynamicNav.isActive,
        closeable: true,
      });
      common.setNavList([...navList]);
    }
  }, [location]);
  return (
    <NavBar
      navList={navList}
      onClick={async (item, index, location) => {
        const { pathname, search, hash } = location;
        const leaveDataModelsTab = pathname.startsWith('/data-model');
        if (leaveDataModelsTab) {
          const lastPath = pathname + search + hash;
          const newNavList = navList.map((item) => {
            const { label } = item;
            if (label === 'Data Models') item.path = lastPath;
            return item;
          });
          common.setNavList([...newNavList]);
        }
      }}
      onClose={async (item) => {
        if (!item.closeable) {
          return;
        }
        if (item.label === ENavLabel.PIPELINE_EDIT) {
          await new Promise<void>((res, rej) => {
            Modal.confirm({
              content: `A pipeline is in editing, are you sure to close it?`,
              onOk() {
                res();
              },
              onCancel() {
                rej();
              },
            });
          });
          closePagePipelineEdit();
        } else if (item.label === ENavLabel.PIPELINE_CREATE) {
          await new Promise<void>((res, rej) => {
            Modal.confirm({
              content: `A pipeline is in creating, are you sure to close it?`,
              onOk() {
                res();
              },
              onCancel() {
                rej();
              },
            });
          });
          closePagePipelineCreate();
        }
        const index = navList.indexOf(item);
        if (index > -1) {
          if (item.isActive(location)) {
            if (window.history.state && window.history.state.idx > 0) {
              navigate(-1);
            } else {
              navigate(EPath.DATA_MODEL_LIST);
            }
          }
          navList.splice(index, 1);
          common.setNavList([...navList]);
        }
      }}
    />
  );
});
export default NavWrapper;

import React, { useEffect, useMemo, useRef } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Button, message, Select, Spin, Table, Tabs } from 'antd';
import { useRequestModelList, useRequestModelSchema } from '@/service/request/data-model';
import { useAntdTable, useSetState, useUpdateEffect } from 'ahooks';
import { ColumnsType } from 'antd/lib/table';
import { IDataModelItem, IDataModelSampleItem, IDataModelSchema } from '@/service/types/data-model';
import TabDataPreview from '@/service/components/TableDataPreview';
import { antdPaginationConfig, checkAccountId, EReactQueryKey } from '@/service/utils/common';
import { getUrlHash, setUrlHash, toUrl } from '@/service/libs/util';
import { EPath } from '@/service/utils/common';
import { IRefTablePipelineList, TablePipelineList } from '@/service/components/TablePipelineList';
import { getDefaultPipelineSetting, toPagePipelineCreate } from '@/service/utils/pipeline';
import { useRequestAccounts } from '@/service/request/account';
import { getTemplateKey } from '@/service/utils/data-model';
import './item.less';
import { useQueryClient } from 'react-query';
import TextTruncation from '@/components/TextTruncation';

const TabDataDefinitions: React.FC<{
  dataModelId: string;
  schema: IDataModelSchema[];
  loading?: boolean;
}> = (props) => {
  const { dataModelId, schema, loading } = props;
  // console.log('schema');
  // console.log(schema);
  const tableRef = useRef<HTMLDivElement>(null);
  const { tableProps, run } = useAntdTable(
    async ({ current, pageSize }) => {
      return {
        total: schema.length,
        list: schema,
      };
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    run({
      current: 1,
      pageSize: 20,
    });
  }, [schema]);
  tableProps.pagination = {
    ...tableProps.pagination,
    ...antdPaginationConfig,
  };
  const columns: ColumnsType<IDataModelSampleItem> = [
    {
      title: 'Column Name',
      dataIndex: ['name'],
    },
    {
      title: 'Type',
      dataIndex: ['type'],
    },
    {
      title: 'Mode',
      dataIndex: ['mode'],
    },
    {
      title: 'Description',
      dataIndex: ['description'],
      render(value, record, index) {
        return <TextTruncation text={value} id={index} />;
      },
    },
  ];
  return <Table columns={columns} {...tableProps} ref={tableRef} rowKey={'name'} sticky={true} loading={loading} />;
};

type TTab = 'data-definitions' | 'data-preview' | 'pipelines';
const DATA_MODEL_TABS: TTab[] = ['data-definitions', 'data-preview', 'pipelines'];
/**
 * global status. Do not depend on any component
 */
const globalStatus = {
  hasShownTabPipelineList: false,
};
const IndexPage: React.FC<{}> = () => {
  const { modelId, version } = useParams();
  const { data: dataModelList, isLoading, isRefetching, error } = useRequestModelList();
  if (!modelId || Boolean(error)) {
    return <Navigate to={EPath.DATA_MODEL_LIST} />;
  }
  let dataModel: IDataModelItem | undefined = undefined;
  if (dataModelList) {
    dataModel = dataModelList.find((it) => it.uuid === modelId);
    if (!dataModel) {
      message.error(`Data-Model with modelId ${modelId} not exist`);
      return <Navigate to={EPath.DATA_MODEL_LIST} />;
    }
  }
  if (dataModel && !version) {
    const { versions } = dataModel;
    if (Array.isArray(versions) && versions.length > 0) {
      // console.log({
      //   path: EPath.DATA_MODEL_DETAIL,
      //   params: {
      //     modelId: modelId,
      //     version: versions[0].version,
      //   },
      // });
      return (
        <Navigate
          to={toUrl({
            path: EPath.DATA_MODEL_DETAIL,
            params: {
              modelId: modelId,
              version: versions[0].version,
            },
          })}
        />
      );
    }
  }
  return dataModel && version ? (
    <DataModelDetail dataModel={dataModel} version={version} />
  ) : (
    <Spin spinning={isLoading || isRefetching} tip={'Loading Data Models...'}>
      <div
        style={{
          minHeight: '500px',
        }}
      />
    </Spin>
  );
};

const DataModelDetail: React.FC<{ dataModel: IDataModelItem; version: string }> = (props) => {
  const { dataModel, version } = props;
  const versions = useMemo(() => dataModel.versions, [dataModel]);
  const { isLoading: loadingPreData, data: accountList } = useRequestAccounts(dataModel.uuid);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const refTablePipelines = useRef<IRefTablePipelineList>({
    refreshData() {},
  });
  const [pageStatus, setPageStatus] = useSetState<{
    accountId: string;
    tab: TTab;
  }>({
    accountId: '',
    tab: getUrlHash(DATA_MODEL_TABS) as TTab,
  });
  const { data: dataSchema, status: loadingSchemaStatus } = useRequestModelSchema(dataModel.uuid, version);

  useEffect(() => {
    globalStatus.hasShownTabPipelineList = false;
    return () => {
      globalStatus.hasShownTabPipelineList = false;
    };
  }, []);
  useEffect(() => {
    setPageStatus({
      accountId: checkAccountId(pageStatus.accountId, accountList),
    });
  }, [accountList]);
  const account = useMemo(() => {
    const { accountId } = pageStatus;
    if (!accountList) {
      return null;
    }
    const item = accountList.find((it) => it.id === accountId);
    if (!item) {
      return null;
    }
    return item;
  }, [accountList, pageStatus.accountId]);

  useUpdateEffect(() => {
    switch (pageStatus.tab) {
      case 'data-definitions':
        // requestDataSchema();
        break;
      case 'pipelines':
        // avoid duplicate request
        if (!globalStatus.hasShownTabPipelineList) {
          globalStatus.hasShownTabPipelineList = true;
          return;
        }
        refTablePipelines.current.refreshData();
        break;
    }
  }, [pageStatus.tab]);

  const { TabPane } = Tabs;
  return (
    <Spin spinning={loadingPreData} tip="Loading...">
      <div className="page-data-model-item">
        <div className="header">
          <div
            style={{
              fontSize: '28px',
              lineHeight: '36px',
              color: '#000',
            }}
          >
            <span>{dataModel.displayName}</span>
            <Select
              size="small"
              disabled={versions.length <= 1}
              options={versions.map((it) => {
                return {
                  label: it.version,
                  value: it.version,
                };
              })}
              style={{
                marginLeft: '12px',
                minWidth: '80px',
              }}
              value={version}
              onChange={(version) => {
                // console.log(v);
                navigate(
                  toUrl({
                    path: EPath.DATA_MODEL_DETAIL,
                    params: {
                      modelId: dataModel.uuid,
                      version,
                    },
                  }),
                );
              }}
            />
          </div>
          <div
            style={{
              color: '#4a4a4a',
              fontSize: '16px',
              lineHeight: '22px',
            }}
          >
            {dataModel.description}
          </div>
          <div
            style={{
              marginTop: '5px',
              color: '#9b9b9b',
              fontSize: '14px',
              lineHeight: '20px',
            }}
          >
            <a
              style={{
                color: '#9b9b9b',
              }}
              onClick={() => {
                navigate(EPath.DATA_MODEL_LIST);
              }}
            >
              All Data Models
            </a>
            <span
              style={{
                padding: '0px 5px',
              }}
            >
              {'>'}
            </span>
            <span>{dataModel.displayName}</span>
          </div>
          {pageStatus.tab === 'data-preview' ? (
            <Select
              showSearch={true}
              size="small"
              filterOption={(key, option) => {
                if (!option || !option.label) {
                  return false;
                }
                return option.label.toLowerCase().indexOf(key.toLowerCase()) > -1;
              }}
              onChange={(v) => {
                setPageStatus({
                  accountId: v,
                });
              }}
              value={pageStatus.accountId}
              options={
                accountList
                  ? accountList.map((it) => {
                      return {
                        value: it.id,
                        label: it.name,
                      };
                    })
                  : []
              }
              placeholder="Select C3 Accounts"
              style={{
                minWidth: '300px',
                position: 'absolute',
                right: '20px',
                bottom: '10px',
              }}
            />
          ) : null}
        </div>
        <div className="content">
          {pageStatus.tab === 'pipelines' ? (
            <Button
              type="primary"
              ghost
              style={{
                position: 'absolute',
                right: '12px',
                top: '0px',
                zIndex: '2',
              }}
              onClick={() => {
                const templateKey = getTemplateKey(dataModel);
                toPagePipelineCreate(
                  Object.assign(getDefaultPipelineSetting(), {
                    templateKey,
                    viewTemplateId: templateKey.viewTemplateId,
                    accountIdList: [pageStatus.accountId],
                  }),
                  navigate,
                );
              }}
            >
              Create New Pipeline
            </Button>
          ) : null}
          <Tabs
            size="small"
            tabBarGutter={2}
            activeKey={pageStatus.tab}
            onChange={(key) => {
              setUrlHash(key);
              setPageStatus({
                tab: key as TTab,
              });
            }}
            type="card"
          >
            <TabPane tab="Data Definitions" key={DATA_MODEL_TABS[0]}>
              <TabDataDefinitions
                dataModelId={dataModel.uuid}
                schema={dataSchema ? dataSchema : []}
                loading={loadingSchemaStatus === 'loading'}
              />
            </TabPane>
            <TabPane tab="Data Preview" key={DATA_MODEL_TABS[1]}>
              <TabDataPreview
                templateKey={{
                  modelId: dataModel.uuid,
                  version,
                }}
                schema={dataSchema ? dataSchema : []}
                account={account}
              />
            </TabPane>
            <TabPane tab="Pipelines" key={DATA_MODEL_TABS[2]}>
              <TablePipelineList
                params={{
                  modelUuid: dataModel.uuid,
                }}
                excludeColumns={['Data']}
                ref={refTablePipelines}
                onActiveStatusChange={() => {
                  queryClient.removeQueries(EReactQueryKey.DATA_MODEL_LIST);
                }}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Spin>
  );
};

export default IndexPage;

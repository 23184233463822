import { useCallback } from 'react';
import { Checkbox, Select } from 'antd';
import { TFileFormat } from '@/service/types';
import { FILE_FORMAT_LIST } from '@/service/utils/pipeline';

/**
 * A warpper component for select file format
 */
const FileFormatWrapper: React.FC<{
  // format of value: avro, csv, parquet, csv.gz, csv.tar.gz, parquet.gz,
  value?: TFileFormat;
  onChange?: (format: string) => void;
}> = (props) => {
  let format = FILE_FORMAT_LIST[0];
  let gz: boolean = false;
  const { value, onChange } = props;
  // console.log('render FileFormatWrapper');
  // console.log(value);
  if (value) {
    const parts = value.split('.');
    if (parts.length > 1 && parts[parts.length - 1] === 'gz') {
      gz = parts[0] !== 'avro';
      format = parts.slice(0, -1).join('.') as TFileFormat;
    } else {
      format = value;
    }
  }
  const getFormat = useCallback((format: string, gz: boolean) => {
    if (gz && format.indexOf('avro') === -1) {
      return `${format}.gz`;
    } else {
      return format;
    }
  }, []);
  return (
    <>
      <Select
        options={FILE_FORMAT_LIST.map((it) => {
          return {
            label: it,
            value: it,
          };
        })}
        value={format}
        onChange={(v) => {
          if (onChange) {
            onChange(getFormat(v, gz));
          }
        }}
      />
      {value && value.indexOf('avro') < 0 && (
        <Checkbox
          style={{
            marginLeft: '20px',
          }}
          checked={gz}
          onChange={(evt) => {
            if (onChange) {
              onChange(getFormat(format, evt.target.checked));
            }
          }}
        >
          gzip
        </Checkbox>
      )}
    </>
  );
};

export default FileFormatWrapper;

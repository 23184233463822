import React from 'react';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getFromSessionStorage, saveToSessionStorage } from '@/service/utils/env';
import { useRequestStatistic } from '@/service/request/pipeline';
import { TablePipelineList } from '@/service/components/TablePipelineList';
import { useNavigate } from 'react-router-dom';
import { getDefaultPipelineSetting, toPagePipelineCreate } from '@/service/utils/pipeline';
import './list.less';
import { useQueryClient } from 'react-query';
import { EReactQueryKey } from '@/service/utils/common';

function getPageSize() {
  return getFromSessionStorage(['pipeline', 'list', 'pageSize'], 10);
}

function setPageSize(pageSize: number) {
  saveToSessionStorage(['pipeline', 'list', 'pageSize'], pageSize);
}

const IndexPage: React.FC<{}> = () => {
  const { data: statistic, refetch: requestStatistics, isLoading } = useRequestStatistic();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const activeCnt = statistic?.activeCount || 0;
  const inActiveCnt = statistic?.inActiveCount || 0;

  return (
    <div className="page-pipeline-list">
      <div className="header">
        <div
          style={{
            marginBottom: '30px',
            fontSize: '28px',
            lineHeight: '36px',
            color: '#000',
          }}
        >
          Pipelines
        </div>
        <div className="statistic">
          <div className="item">
            <div className="title">Total</div>
            <div className="value">{isLoading ? <LoadingOutlined /> : activeCnt + inActiveCnt}</div>
          </div>
          <div className="item">
            <div className="title">Active</div>
            <div
              className="value"
              style={{
                color: '#46B04A',
              }}
            >
              {isLoading ? <LoadingOutlined /> : activeCnt}
            </div>
          </div>
        </div>
        <Button
          type="primary"
          size="middle"
          ghost
          style={{
            position: 'absolute',
            right: '12px',
            top: 'calc(20px + 10px)',
            zIndex: '2',
          }}
          onClick={() => {
            toPagePipelineCreate(getDefaultPipelineSetting(), navigate);
          }}
        >
          Create New Pipeline
        </Button>
      </div>
      <div className="list">
        <TablePipelineList
          params={{
            pageNum: 1,
            pageSize: getPageSize(),
          }}
          onStatusChange={(status) => {
            if (status.pageSize) {
              setPageSize(status.pageSize);
            }
          }}
          onActiveStatusChange={() => {
            requestStatistics();
            queryClient.removeQueries(EReactQueryKey.DATA_MODEL_LIST);
          }}
        />
      </div>
    </div>
  );
};
export default IndexPage;

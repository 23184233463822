import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getMdsServer } from '../utils/env';
import { message } from 'antd';
import { toUrl } from '../libs/util';

export interface IRequestConfig extends AxiosRequestConfig {
  /**
   * variable in path, support the format:
   * '/v1.0/{groupId}/memeber/{useId}'
   * or
   * '/v1.0/:groupId/memeber/:useId'
   */
  urlParams?: {
    [key: string]: string;
  };
  /**
   * show error message by antd.message or not
   * default is false, as it is better not show error message than show an unexpected message to user.
   */
  showErrorMessage?: boolean;
}
export const axios = Axios.create({
  baseURL: getMdsServer() + '/api/system-configuration/v1.0',
  withCredentials: true,
});

export async function axiosRequest(requestConfig: IRequestConfig) {
  const { urlParams, url, showErrorMessage, timeoutErrorMessage } = requestConfig;
  if (url && urlParams) {
    requestConfig.url = toUrl({
      path: url,
      params: urlParams,
    });
    delete requestConfig.urlParams;
  }
  try {
    return await axios.request(requestConfig);
  } catch (error: any) {
    let errorMessage!: string;
    if (error.isAxiosError) {
      const { response } = error as AxiosError<any, any>;
      if (response && response.data && response.data.message) {
        errorMessage = response.data.message;
      }
    } else if (timeoutErrorMessage) {
      errorMessage = timeoutErrorMessage as string;
    }
    if (errorMessage) {
      error.message = errorMessage;
    }
    if (showErrorMessage) {
      message.error(error.message);
    }
    throw error;
  }
}
export async function requestAxiosData(config: IRequestConfig) {
  const response = await axiosRequest(config);
  return response.data;
}
export async function requestData(config: IRequestConfig) {
  const axiosData = await requestAxiosData(config);
  // throw new Error('abc');
  return axiosData.data;
}

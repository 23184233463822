import { getBuildEnv, getBuildTarget } from '@/service/utils/env';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function startSentryMonitor() {
  Sentry.init({
    dsn: 'https://0d7d0746a57f4448b94b06d27260e2fe@o123592.ingest.sentry.io/6276374',
    environment: getBuildTarget(),
    release: `${getBuildEnv('appName')}@${getBuildEnv('buildTime')}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

export function stopSentryMonitor() {
  Sentry.init({});
}

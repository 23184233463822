import React from 'react';
import './index.less';

interface ITextTruncation {
  id: string | number;
  text: string;
}

const TextTruncation: React.FC<ITextTruncation> = ({ id, text }) => {
  return (
    <div className="wrapper">
      <input type="checkbox" className="exp" id={`exp${id}`} />
      <div className="text">
        <label className="btn" htmlFor={`exp${id}`} />
        {text}
      </div>
    </div>
  );
};

export default TextTruncation;

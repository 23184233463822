import { useEffect, useRef, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table/interface';
import { IAccount } from '@/service/request/account';
import { useRequestModelSample } from '@/service/request/data-model';
import { IDataModelSampleItem, IDataModelSchema, IDataModelTemplateKey } from '@/service/types';
import { message, Table } from 'antd';
import { antdPaginationConfig } from '@/service/utils/common';

const TableDataPreview: React.FC<{
  templateKey: Pick<IDataModelTemplateKey, 'modelId' | 'version'>;
  /** TODO: may be useless, but not sure */
  schema?: IDataModelSchema[];
  account?: IAccount | null;
}> = (props) => {
  const {
    templateKey: { modelId, version },
    account,
  } = props;
  const tableRef = useRef<HTMLDivElement>(null);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 20,
    total: 0,
    ...antdPaginationConfig,
  });
  const {
    data: modelSampleData,
    isLoading,
    error: modelSampleError,
  } = useRequestModelSample({
    urlParams: {
      modelId,
      version,
    },
    params: {
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
      ...(account
        ? {
            customerId: account.id,
            customerName: account.name,
            customerTimezone: account.tzName,
          }
        : {}),
    },
  });

  useEffect(() => {
    modelSampleData && setPagination((p) => ({ ...p, total: modelSampleData.total }));
  }, [modelSampleData]);

  useEffect(() => {
    if (modelSampleError instanceof Error) {
      message.error(modelSampleError.message);
    }
  }, [modelSampleError]);

  const dataIsEmpty = modelSampleData?.sampleDataList.length === 0;
  let columns: ColumnsType<IDataModelSampleItem> = [];
  if (!dataIsEmpty && modelSampleData) {
    columns = Object.keys(modelSampleData.sampleDataList[0]).map((key) => {
      return {
        title: key,
        dataIndex: [key],
        key,
      };
    });
  }

  const tableChange = (pagination: TablePaginationConfig) => {
    setPagination({ ...pagination });
  };

  return (
    <Table
      ref={tableRef}
      loading={isLoading}
      columns={columns}
      dataSource={modelSampleData ? modelSampleData?.sampleDataList : []}
      rowKey={() => String(Math.random())}
      sticky={true}
      scroll={{ x: true }}
      pagination={pagination}
      onChange={tableChange}
      summary={() => {
        return (
          <tr
            style={{
              visibility: 'hidden',
            }}
          >
            {columns &&
              columns.map((it, index) => {
                return (
                  <td
                    key={index}
                    className="daas-ant-table-cell"
                    style={{
                      padding: '0px 20px',
                      borderColor: 'transparent',
                    }}
                  >
                    {it.title}
                  </td>
                );
              })}
          </tr>
        );
      }}
    />
  );
};

export default TableDataPreview;

import { IRefPipelineCreate } from '@/pages/pipeline/create';
import { IRefPipelineEdit } from '@/pages/pipeline/edit';
import { makeAutoObservable } from 'mobx';
import { NAV_LIST } from '@/service/utils/common';
import { INavItem } from '@/service/components/navbar';

export const globalInfo: {
  refComponentPipelineEdit: IRefPipelineEdit | null;
  refComponentPipelineCreate: IRefPipelineCreate | null;
} = {
  refComponentPipelineEdit: null,
  refComponentPipelineCreate: null,
};
class CommonStore {
  constructor() {
    makeAutoObservable(this);
  }
  navList: INavItem[] = NAV_LIST;
  setNavList(menuList: INavItem[]) {
    this.navList = menuList;
  }
}

export const common = new CommonStore();

import { deepMerge, overrideObj, toCamelCase } from '@/service/libs/util';
import { getDefaultPipelineSetting } from './pipeline';
import { IAppHubProps, IPipelineSetting } from '../types';

/**
 * variables paased from webpack by DefinePlugin
 */
type IClientEnv = {
  readonly NODE_ENV: 'development' | 'production';
  readonly PUBLIC_URL: string;
  readonly appName: string;
  readonly buildTime: string;
};

/**
 * enviroment inject during CI/CD
 * NOTICE: the change of CI environment may have effect on code logic
 */
type ICIEnv = {
  readonly REACT_APP_ENV: 'DEV1' | 'STG1' | 'PROD';
  readonly REACT_APP_API_HOST: string;
};
type ENV = ICIEnv & IClientEnv;
export type TBuildTarget = IClientEnv['NODE_ENV'] | ICIEnv['REACT_APP_ENV'];

export function getBuildEnv<Key extends keyof ENV>(key: Key): ENV[Key] {
  return process.env[key] as ENV[Key];
}

export function getBuildTarget(): TBuildTarget {
  const ciEnv = getBuildEnv('REACT_APP_ENV');
  if (ciEnv) {
    return ciEnv;
  }
  const nodeEnv = getBuildEnv('NODE_ENV');
  return nodeEnv;
}
/**
 * get baseUrl depends on buildTarget
 * @returns baseUrl for BrowserRouter
 */
export function getBaseUrl() {
  const plusePrefix = '/app/conviva-connect';
  if (window.__POWERED_BY_QIANKUN__) {
    return plusePrefix;
  }
  return '';
}
export function getHref(relativePath: string) {
  return `${getBaseUrl()}${relativePath}`.replaceAll('//', '/');
}
export function getRelativePath(path: string) {
  const baseUrl = getBaseUrl();
  if (path.startsWith(baseUrl)) {
    return path.substring(baseUrl.length);
  }
  return path;
}
export function getMdsServer() {
  const ciApiHost = getBuildEnv('REACT_APP_API_HOST');
  if (ciApiHost) {
    return ciApiHost;
  }
  let url = 'https://portal.mds.conviva.com';
  switch (getBuildTarget()) {
    case 'development':
    case 'DEV1':
      url = 'https://portal-dev.mds.conviva.com';
      // url = 'http://127.0.0.1:30334';
      break;
    case 'STG1':
      url = 'https://portal-stg.mds.conviva.com';
      break;
    case 'production':
    case 'PROD':
      url = 'https://portal.mds.conviva.com';
      break;
  }
  return url;
}

let appHubProps: IAppHubProps | null = null;
export function setAppHubProps(props: IAppHubProps) {
  appHubProps = props;
}
export function getAppHubProps() {
  return appHubProps;
}

export function permission4ConvivaConnect() {
  const appHubProps = getAppHubProps();
  if (!appHubProps) {
    return true;
  } else {
    const { user } = appHubProps as IAppHubProps;
    return user && user.user.role === 'Admin';
  }
}

interface ILocalStorage {
  pipeline: {
    list: {
      pageSize: number;
      nodeEnv: TBuildTarget;
    };
    edit: {
      config: Partial<IPipelineSetting>;
    };
  };
}

function storageOperation<TData>(storage: Storage, defalutLocalData: TData, storageKey: string) {
  let localData: TData | null = null;
  function initLocalData() {
    if (localData) {
      return;
    }
    localData = defalutLocalData;
    let data = {};
    try {
      const str = storage.getItem(storageKey);
      if (!str) {
        throw new Error('ignore');
      }
      data = JSON.parse(str);
      overrideObj(localData, data);
    } catch (err) {
      storage.setItem(storageKey, JSON.stringify(localData));
    }
  }
  function getFromStorage<K1 extends keyof TData, K2 extends keyof TData[K1], K3 extends keyof TData[K1][K2]>(
    keys: [K1, K2, K3],
    defaultValue?: TData[K1][K2][K3],
  ): TData[K1][K2][K3] {
    if (localData === null) {
      initLocalData();
    }
    const value = keys.reduce((sum, key) => {
      if (!sum) {
        return null;
      }
      // @ts-ignore
      if (!sum.hasOwnProperty(key)) {
        return null;
      }
      // @ts-ignore
      return sum[key];
    }, localData);
    // @ts-ignore
    return value ? value : defaultValue;
  }
  function setToStorage<K1 extends keyof TData, K2 extends keyof TData[K1], K3 extends keyof TData[K1][K2]>(
    keys: [K1, K2, K3],
    value: TData[K1][K2][K3],
  ) {
    if (localData === null) {
      initLocalData();
    }
    if (localData) {
      const current = localData[keys[0]][keys[1]][keys[2]];
      if (current !== value) {
        localData[keys[0]][keys[1]][keys[2]] = value;
        storage.setItem(storageKey, JSON.stringify(localData));
      }
    }
  }
  return { setToStorage, getFromStorage };
}

const { setToStorage: saveToLocalStorage, getFromStorage: getFromLocalStorage } = storageOperation<ILocalStorage>(
  localStorage,
  {
    pipeline: {
      list: {
        pageSize: 10,
        nodeEnv: getBuildTarget(),
      },
      edit: {
        config: {},
      },
    },
  },
  toCamelCase(getBuildEnv('appName') as string),
);

const { setToStorage: saveToSessionStorage, getFromStorage: getFromSessionStorage } = storageOperation<ILocalStorage>(
  sessionStorage,
  {
    pipeline: {
      list: {
        pageSize: 10,
        nodeEnv: getBuildTarget(),
      },
      edit: {
        config: getDefaultPipelineSetting(),
      },
    },
  },
  toCamelCase(getBuildEnv('appName') as string),
);
export { saveToLocalStorage, getFromLocalStorage, saveToSessionStorage, getFromSessionStorage };

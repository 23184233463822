import ReactDOM from 'react-dom';
import { useUpdate } from 'ahooks';
import { memo, RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
type Children = React.ReactNode | null;
interface IProps {
  activeName: string;
  isAsyncInclude?: boolean;
  include?: Array<string>;
  exclude?: Array<string>;
  maxLen?: number;
  children: Children;
}

function isNil(v: any) {
  return v === null || v === undefined;
}
function not(v: any) {
  return !Boolean(v);
}
const KeepAlive: React.FC<IProps> = (props) => {
  const { activeName, children, exclude, include, isAsyncInclude, maxLen = 10 } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const components = useRef<Array<{ name: string; ele: Children }>>([]);
  const [asyncInclude] = useState<boolean>(Boolean(isAsyncInclude));
  const update = useUpdate();
  useLayoutEffect(() => {
    if (isNil(activeName)) {
      return;
    }
    if (components.current.length >= maxLen) {
      components.current = components.current.slice(1);
    }
    // add component if not exist in components array
    const component = components.current.find((res) => res.name === activeName);
    if (isNil(component)) {
      components.current = [
        ...components.current,
        {
          name: activeName,
          ele: children,
        },
      ];
      if (not(asyncInclude)) {
        update();
      }
    }
    return () => {
      // handle black/white list
      if (isNil(exclude) && isNil(include)) {
        return;
      }
      components.current = components.current.filter(({ name }) => {
        if (exclude && exclude.includes(name)) {
          return false;
        }
        if (include) {
          return include.includes(name);
        }
        return true;
      });
    };
  }, [children, activeName, exclude, maxLen, include, update, asyncInclude]);
  return (
    <>
      <div ref={containerRef} className="keep-alive" />
      {components.current.map(({ name, ele }) => {
        return (
          <Component active={name === activeName} renderDiv={containerRef} name={name} key={name}>
            {ele}
          </Component>
        );
      })}
    </>
  );
};
export default memo(KeepAlive);
interface ComponentProps {
  active: boolean;
  children: Children;
  name: string;
  renderDiv: RefObject<HTMLDivElement>;
}
// render actived compmonent, unactived components will be removed to document.createElement('div') by createPortal
function Component({ active, children, name, renderDiv }: ComponentProps) {
  const [targetElement] = useState(() => document.createElement('div'));
  const activatedRef = useRef(false);
  activatedRef.current = activatedRef.current || active;
  useEffect(() => {
    if (active) {
      // components matched
      renderDiv.current?.appendChild(targetElement);
    } else {
      try {
        // remove ummatched components
        renderDiv.current?.removeChild(targetElement);
      } catch (e) {}
    }
  }, [active, name, renderDiv, targetElement]);
  useEffect(() => {
    targetElement.setAttribute('id', name);
  }, [name, targetElement]);
  // render vnode to document.createElement('div')
  return <>{activatedRef.current && ReactDOM.createPortal(children, targetElement)}</>;
}
export const KeepAliveComponent = memo(Component);

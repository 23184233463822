import { useCallback, useState } from 'react';
import { FormInstance } from 'rc-field-form';
import { AUTH_RELATED_PROPS } from '@/pages/pipeline/components/Setting/contant';

type TSinkType = 'GCS' | 'S3' | 'SFTP';

enum ESinkType {
  GCS = 'GCS',
  S3 = 'S3',
  SFTP = 'SFTP',
}

enum ECredentialKey {
  serviceAccountJSON = 'serviceAccountJSON',
  accessKey = 'accessKey',
  privateKey = 'privateKey',
  password = 'password',
}

const SINKTYPE_RELATED_CREDENTIAL = {
  [ESinkType.GCS]: [ECredentialKey.serviceAccountJSON],
  [ESinkType.S3]: [ECredentialKey.accessKey],
  [ESinkType.SFTP]: [ECredentialKey.privateKey, ECredentialKey.password],
};

interface IUseEncryptCredential {
  disabled?: boolean;
  form: FormInstance;
}

export interface ICredential {
  key: string;
  value: string;
  sinkType: string;
  encrypted: boolean;
}

// encrypt credential display
export const ENCRYPT_CREDENTIAL_DISPLAY = '******';

const useEncryptCredential = ({ form, disabled }: IUseEncryptCredential) => {
  const [credential, setCredential] = useState<ICredential>();
  const [authentication, setAuthentication] = useState<{ [key: string]: string }>();
  const [sameKeyForSftp, setSameKeyForSftp] = useState<boolean>(true);

  // credential Key(password/privateKey) changed
  const changeCredentialKeyForSftp = useCallback(
    (value: string) => {
      if (credential && credential.key !== value) {
        setSameKeyForSftp(false);
      } else {
        setSameKeyForSftp(true);
      }
    },
    [credential],
  );

  // determine when editing the SFTP type, credential(password/privateKey) changed at the time of edit is not the same as the initial
  const noSwitchCredentialKeyForSFTP = useCallback(() => {
    const credentialSinkType = credential?.sinkType;
    if (!credentialSinkType || credentialSinkType !== 'SFTP') return true;
    return sameKeyForSftp;
  }, [sameKeyForSftp, credential]);

  // Determine that the sinkType changed at the time of edit is not the same as the initial
  const noSwitchSinkType = useCallback(() => {
    const credentialSinkType = credential?.sinkType;
    if (!credentialSinkType) return true;
    const sinkType = form.getFieldValue(['sinkType']);
    return credentialSinkType === sinkType;
  }, [form, credential?.sinkType]);

  // Cache auth related props
  const cacheCredentialRelatedAuth = useCallback(
    (init?: boolean) => {
      const sinkType: TSinkType = form.getFieldValue(['sinkType']);
      const properties = form.getFieldValue(['sink', 'properties']);
      if (init || (noSwitchSinkType() && sinkType)) {
        const auth: { [key: string]: string } = {};
        AUTH_RELATED_PROPS[sinkType].forEach((it) => {
          if (properties?.[it]) {
            auth[it] = properties?.[it];
          }
        });
        setAuthentication(auth);
      }
    },
    [form, noSwitchSinkType],
  );

  // save unencrypted credential
  const saveCredential = useCallback(
    (credentialKey?: string) => {
      if (disabled) return;
      const sinkType = form.getFieldValue(['sinkType']);
      const properties = form.getFieldValue(['sink', 'properties']);
      setCredential((prevState) => {
        if (credentialKey) {
          return {
            ...prevState,
            key: credentialKey,
            value: properties[credentialKey],
            sinkType: sinkType,
            encrypted: true,
          };
        } else if (prevState?.key) {
          return {
            ...prevState,
            value: properties[prevState?.key],
            sinkType: sinkType,
            encrypted: false,
          };
        }
        return prevState;
      });
    },
    [disabled, form],
  );

  // show encrypt credential
  const showEncryptCredential = useCallback(() => {
    if (disabled) return;
    const fromFieldsValue = form?.getFieldsValue();
    const sinkType = form.getFieldValue(['sinkType']) as TSinkType;
    const properties = form.getFieldValue(['sink', 'properties']);
    const filterProperties: { [key: string]: string } = {};
    AUTH_RELATED_PROPS[sinkType].forEach((it) => {
      if (properties?.[it] !== undefined) {
        filterProperties[it] = properties?.[it];
      }
    });
    const keys = SINKTYPE_RELATED_CREDENTIAL[sinkType];
    const key = keys.length === 2 ? (filterProperties?.password !== undefined ? keys[1] : keys[0]) : keys?.[0];
    if (key) {
      let formData = {
        ...fromFieldsValue,
        sink: {
          properties: {
            ...filterProperties,
            [key]: ENCRYPT_CREDENTIAL_DISPLAY,
          },
        },
      };
      form.setFieldsValue(formData);
    }
  }, [disabled, form]);

  // save unencrypted credential and encrypt credential display
  const saveAndEncryptCredential = useCallback(() => {
    if (disabled) return;
    // 1. save credential
    saveCredential();
    // 2. encrypt credential display
    showEncryptCredential();
  }, [disabled, saveCredential, showEncryptCredential]);

  // save unencrypted credential and encrypt credential display
  const initializeCredential = useCallback(
    (key?: string) => {
      if (disabled) return;
      // 1. save credential
      saveCredential(key);
      cacheCredentialRelatedAuth(true);
      // 2. encrypt credential display
      showEncryptCredential();
    },
    [disabled, saveCredential, cacheCredentialRelatedAuth, showEncryptCredential],
  );

  // get the formFieldsValue for unencrypted credential
  const getUnencryptedFormValue = useCallback(() => {
    const fromFieldsValue = form?.getFieldsValue();
    const credentialKey = credential?.key;
    let formData = { ...fromFieldsValue, encrypted: false };
    if (!disabled && credentialKey && noSwitchSinkType() && noSwitchCredentialKeyForSFTP()) {
      formData = {
        ...fromFieldsValue,
        sink: {
          properties: {
            ...fromFieldsValue?.sink?.properties,
            [credentialKey]: credential.value,
          },
        },
        encrypted: credential?.encrypted,
      };
    }
    return formData;
  }, [form, disabled, credential, noSwitchSinkType, noSwitchCredentialKeyForSFTP]);

  // show the formFieldsValue without credential
  const showCredential = useCallback(() => {
    if (disabled && !noSwitchSinkType()) return;
    const fromFieldsValue = form?.getFieldsValue();
    const credentialKey = credential?.key;
    const encrypted = credential?.encrypted;
    if (credentialKey) {
      let formData = {
        ...fromFieldsValue,
        sink: {
          properties: {
            ...fromFieldsValue?.sink?.properties,
            [credentialKey]: encrypted ? '' : credential?.value,
          },
        },
      };
      form.setFieldsValue(formData);
    }
  }, [form, disabled, credential, noSwitchSinkType]);

  // cancel modify credential
  const cancelModifyCredential = useCallback(() => {
    if (disabled && !noSwitchSinkType()) return;
    const fromFieldsValue = form?.getFieldsValue();
    const credentialKey = credential?.key;
    if (credentialKey) {
      let formData = {
        ...fromFieldsValue,
        sink: {
          properties: {
            ...fromFieldsValue?.sink?.properties,
            [credentialKey]: ENCRYPT_CREDENTIAL_DISPLAY,
          },
        },
      };
      form.setFieldsValue(formData);
    }
  }, [form, disabled, credential, noSwitchSinkType]);

  // Initialize
  const initializeCredentialKey = useCallback(() => {
    if (disabled) return;
    const sinkType = form.getFieldValue(['sinkType']);
    const properties = form.getFieldValue(['sink', 'properties']);
    switch (sinkType) {
      case ESinkType.GCS:
        initializeCredential(ECredentialKey.serviceAccountJSON);
        break;
      case ESinkType.S3:
        initializeCredential(ECredentialKey.accessKey);
        break;
      case ESinkType.SFTP:
        if (properties?.privateKey) {
          initializeCredential(ECredentialKey.privateKey);
        } else {
          initializeCredential(ECredentialKey.password);
        }
        break;
    }
  }, [form, disabled, initializeCredential]);

  // reset auth related props
  const resetAuthRelatedProps = useCallback(() => {
    const fromFieldsValue = form?.getFieldsValue();
    if (!noSwitchSinkType()) {
      const sinkType: TSinkType = form.getFieldValue(['sinkType']);
      const namePath = AUTH_RELATED_PROPS[sinkType].map((it) => ['sink', 'properties', it]);
      form.resetFields(namePath);
    } else {
      const credentialKey = credential?.key;
      const sinkType = credential?.sinkType as TSinkType;
      if (credentialKey && sinkType) {
        const properties = fromFieldsValue?.sink?.properties;
        const filterProperties: { [key: string]: string } = {};
        AUTH_RELATED_PROPS[sinkType].forEach((it) => {
          if (properties?.[it]) {
            filterProperties[it] = properties?.[it];
          }
        });
        let formData = {
          ...fromFieldsValue,
          sink: {
            properties: {
              ...filterProperties,
              ...authentication,
              [credentialKey]: ENCRYPT_CREDENTIAL_DISPLAY,
            },
          },
        };
        form.setFieldsValue(formData);
      }
    }
    setSameKeyForSftp(true);
  }, [form, credential, noSwitchSinkType, authentication]);

  return {
    credential,
    sameKeyForSftp,
    changeCredentialKeyForSftp,
    noSwitchSinkType,
    resetAuthRelatedProps,
    cacheCredentialRelatedAuth,
    initializeCredentialKey,
    saveAndEncryptCredential,
    getUnencryptedFormValue,
    showCredential,
    cancelModifyCredential,
  };
};
export default useEncryptCredential;

import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useRef } from 'react';
import { useRequestModelList } from '@/service/request/data-model';
import { message, Spin } from 'antd';
import './list.less';
import { toUrl } from '@/service/libs/util';
import { EPath } from '@/service/utils/common';

const IndexPage: React.FC<{}> = () => {
  const { data: dataModelList, status, error, isFetching } = useRequestModelList();
  const navigate = useNavigate();
  return (
    <div className="page-data-model-list">
      <div className="header">
        <div
          style={{
            fontSize: '28px',
            lineHeight: '36px',
            marginTop: '20px',
            marginBottom: '30px',
            padding: '0px 20px',
          }}
        >
          Data Models
        </div>
      </div>
      <Spin spinning={status === 'loading' || isFetching} tip="Loading..." wrapperClassName="wrapper-loading">
        <div className="list">
          <div
            style={{
              fontSize: '24px',
              lineHeight: '32px',
              fontWeight: '600',
              color: '#4a4a4a',
              marginBottom: '10px',
            }}
          >
            Sessions
          </div>
          {Array.isArray(dataModelList) &&
            dataModelList.map((it, index) => {
              const { displayName, description, uuid, activePipelineCount, versions = [] } = it;
              // const [{ version }] = versions;
              return (
                <div
                  key={index}
                  className="item"
                  onClick={() => {
                    if (versions.length === 0) {
                      message.error(`The DataModel has no version`);
                      return;
                    }
                    navigate(
                      toUrl({
                        path: EPath.DATA_MODEL_DETAIL,
                        params: {
                          modelId: uuid,
                          version: versions[0].version,
                        },
                      }),
                    );
                  }}
                >
                  <div className="name">{displayName}</div>
                  <div className="description">{description}</div>
                  {activePipelineCount > 0 ? (
                    <div className="active-pipeline-count">{activePipelineCount} Active Pipelines</div>
                  ) : null}
                </div>
              );
            })}
        </div>
      </Spin>
    </div>
  );
};
export default IndexPage;

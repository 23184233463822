import React, { useMemo, useState } from 'react';
import { IConnectionTestStatus } from '@/pages/pipeline/components/Setting/typed';
import { FormInstance } from 'rc-field-form';
import { IPipelineSetting } from '@/service/types';
import classNames from 'classnames';
import { Button, Checkbox, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

interface ISettingSnowflakeProps {
  onConnectionTest: (opt?: { showSuccess: boolean }) => void;
  status: IConnectionTestStatus;
  form: FormInstance<IPipelineSetting>;
}

const SettingSnowflakeProps: React.FC<ISettingSnowflakeProps> = (props) => {
  const {
    onConnectionTest,
    status: { requesting, errorMessage },
    form,
  } = props;
  const { sinkType } = useMemo(() => form.getFieldsValue(), [form]);
  const [checked, setChecked] = useState(false);
  return (
    <>
      <div className={classNames(['category', errorMessage ? 'error' : ''])}>
        <div className="header">
          <span className="title">{`Authentication for Sink ${sinkType ? sinkType : ''}`}</span>
          <Button
            loading={requesting}
            type="link"
            size="small"
            onClick={(evt) => {
              evt.stopPropagation();
              onConnectionTest({
                showSuccess: true,
              });
            }}
          >
            Test Connection
          </Button>
        </div>
        <Form.Item
          key={['sink', 'properties', 'accountName'].join('')}
          name={['sink', 'properties', 'accountName']}
          label="accountName"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `accountName is required for Snowflake sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'database'].join('')}
          name={['sink', 'properties', 'database']}
          label="database"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `database is required for Snowflake sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'schemaName'].join('')}
          name={['sink', 'properties', 'schemaName']}
          label="schemaName"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `schemaName is required for Snowflake sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'tableName'].join('')}
          name={['sink', 'properties', 'tableName']}
          label="tableName"
          required
          rules={[
            {
              type: 'string',
              required: true,
              message: `tableName is required for Snowflake sink`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          key={['sink', 'properties', 'keyPairEnabled'].join('')}
          name={['sink', 'properties', 'keyPairEnabled']}
          label="keyPairEnabled"
          getValueProps={(v: string) => {
            const checked = v === 'true';
            setChecked(checked);
            return {
              checked,
            };
          }}
          getValueFromEvent={(evt) => {
            try {
              return evt.target.checked ? 'true' : 'false';
            } catch (err) {
              return 'false';
            }
          }}
        >
          <Checkbox checked={true}>enabled</Checkbox>
        </Form.Item>
        {checked ? (
          <Form.Item
            key={['sink', 'properties', 'privateKey'].join('')}
            name={['sink', 'properties', 'privateKey']}
            label="privateKey"
            required
            rules={[
              {
                type: 'string',
                required: true,
                message: `privateKey is required for Snowflake sink`,
              },
            ]}
          >
            <TextArea rows={3} />
          </Form.Item>
        ) : (
          [
            <Form.Item
              key={['sink', 'properties', 'username'].join('')}
              name={['sink', 'properties', 'username']}
              label="username"
              required
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: `username is required for Snowflake sink`,
                },
              ]}
            >
              <Input />
            </Form.Item>,
            <Form.Item
              key={['sink', 'properties', 'password'].join('')}
              name={['sink', 'properties', 'password']}
              label="password"
              required
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: `password is required for Snowflake sink`,
                },
              ]}
            >
              <Input />
            </Form.Item>,
          ]
        )}
        {errorMessage ? <div className="error-message">{errorMessage}</div> : null}
      </div>
    </>
  );
};

export default SettingSnowflakeProps;

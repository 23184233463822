import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, FormInstance, Select } from 'antd';
import type { SelectProps } from 'antd';
import { useRequestSessionTags } from '@/service/request/session-tags';
import { IPipelineSetting, IPipelineSettingColumn, ISessionTags } from '@/service/types';
import styles from './RuleMapperForTags.module.less';
import stylesRule from './RuleMapper.module.less';

/**
 * Doe's duplication of select options object array
 * @param arr
 * @param uniId
 */
function uniqueFunc(arr: SelectProps['options'], uniId: string) {
  if (!arr) return [];
  const res = new Map();
  return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
}

const RuleMapperForTags: React.FC<{
  form?: FormInstance<IPipelineSetting>;
  setting?: Partial<IPipelineSetting> | null;
  record?: IPipelineSettingColumn;
  editable?: boolean;
  onChange?: (value: ISessionTags[]) => void;
}> = (props) => {
  const { setting, record, editable = true, onChange, form } = props;
  const repeatedFieldsFilter = setting?.repeatedFieldsFilter;
  const pageData = form?.getFieldsValue();

  const [options, setOptions] = useState([] as SelectProps['options']);
  const [value, setValue] = useState([] as string[]);

  const modelId = useMemo(() => {
    let modelId: string | undefined = '';
    if (pageData) {
      modelId = pageData.templateKey.modelId;
    }
    if (typeof modelId == 'undefined') {
      modelId = '';
    }
    return modelId;
  }, [pageData]);

  const accountIdList = useMemo(() => {
    let accountIdList = '';
    if (pageData) {
      accountIdList = pageData.accountIdList.join('&');
    }
    return accountIdList;
  }, [pageData]);

  const { data } = useRequestSessionTags({
    modelId: modelId,
    customerIds: accountIdList,
  });

  useEffect(() => {
    if (repeatedFieldsFilter) {
      setValue(repeatedFieldsFilter[0]?.selectedValues);
    }
  }, [repeatedFieldsFilter]);

  useEffect(() => {
    if (Array.isArray(data?.keys)) {
      const selectValue = data.keys.map((value: string) => ({
        label: value,
        value,
      }));
      setOptions(selectValue);
      if (Array.isArray(repeatedFieldsFilter) && repeatedFieldsFilter.length > 0) {
        const nestedFilterFieldsOptions = repeatedFieldsFilter[0]?.selectedValues.map((value) => ({
          label: value,
          value: value,
        }));
        const unitedOptions = [...nestedFilterFieldsOptions, ...selectValue];
        const uniqueOptions = uniqueFunc(unitedOptions, 'label');
        setOptions(uniqueOptions);
      }
    }
  }, [data, repeatedFieldsFilter]);

  const handleChange = useCallback(
    (value: string[]) => {
      const nestedFilterFieldItem = {
        name: 'SessionTags',
        selectedValues: value,
        operator: 'includes',
        key: 'key',
      };
      setValue(value);
      if (onChange) {
        onChange([nestedFilterFieldItem]);
      }
    },
    [onChange],
  );

  const handleAddRule = useCallback(() => {
    const nestedFilterFieldItem = {
      name: 'SessionTags',
      selectedValues: [],
      operator: 'includes',
      key: 'key',
    };
    if (onChange) {
      onChange([nestedFilterFieldItem]);
    }
  }, [onChange]);

  const handleDeleteRule = useCallback(() => {
    delete setting?.repeatedFieldsFilter;
    setValue([]);
    if (onChange) {
      onChange([]);
    }
  }, [onChange, setting?.repeatedFieldsFilter]);

  const display = useMemo(() => {
    return (
      (repeatedFieldsFilter && repeatedFieldsFilter?.length > 0) ||
      (record?.repeatedFieldsFilter && record?.repeatedFieldsFilter.length > 0)
    );
  }, [record?.repeatedFieldsFilter, repeatedFieldsFilter]);

  const ruleDom = () => {
    return (
      <div className={styles.ruleDom}>
        <span className={styles.includesSpan}>includes</span>
        <Select
          className="session-tags-select"
          value={value}
          mode="multiple"
          allowClear
          showArrow
          style={{ width: '176px' }}
          placeholder="Select or search to set a value"
          onChange={handleChange}
          options={options}
        />
      </div>
    );
  };

  const deleteButtonDom = () => {
    return (
      <div className={styles.deleteButton}>
        <span className={stylesRule.button} style={{ color: 'red' }} onClick={handleDeleteRule}>
          x
        </span>
      </div>
    );
  };

  return (
    <>
      {display ? (
        <div className={styles.ruleMapperForTags}>
          {ruleDom()} {deleteButtonDom()}
        </div>
      ) : null}
      {editable ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className={styles.ruleMapperForTags}
        >
          <Button onClick={handleAddRule} size="small" style={{ display: display ? 'none' : 'inline-block' }}>
            Add Rule
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default RuleMapperForTags;

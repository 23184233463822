import { IPipelineSetting, IPipelineSettingColumn } from '@/service/types';
import { COLUMN_FILTER_MAP } from '@/service/utils/pipeline';
import { useUpdate } from 'ahooks';
import { Input, Table, Popover, FormInstance } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd/lib/select';
import { ColumnsType } from 'antd/lib/table/interface';
import RuleMapper from '../RuleMapper';
import RuleMapperForTags from '@/pages/pipeline/components/RuleMapperForTags';
import styles from '@/pages/pipeline/components/Setting/TableColumnConfig.module.less';
import TextTruncation from '@/components/TextTruncation';
import { EDataModelSchemaType } from '@/service/types/enum';

const conditionOptions: DefaultOptionType[] = Object.entries(COLUMN_FILTER_MAP).map(([key, value]) => {
  return {
    label: value,
    value: key,
  };
});
const noStringTypeConditionOptions: DefaultOptionType[] = conditionOptions.filter(
  (item) => item.value !== COLUMN_FILTER_MAP.contains && item.value !== COLUMN_FILTER_MAP['not contains'],
);
const width = {
  condtion: 120,
  value: 180,
  action: 75,
  sessionTagsExtraMargin: 50,
};
const COLUMN_FILTER_WIDTH = Object.values(width).reduce((sum, it) => sum + it, 0);

const TableColumnConfig: React.FC<{
  form?: FormInstance<IPipelineSetting>;
  setting?: Partial<IPipelineSetting> | null;
  transformation?: IPipelineSettingColumn[];
  onChange?: (value: IPipelineSettingColumn[]) => void;
}> = (props) => {
  const reRender = useUpdate();
  const { transformation = [], onChange = (value: IPipelineSettingColumn[]) => {}, setting, form } = props;

  const columns: ColumnsType<IPipelineSettingColumn> = [
    {
      title: 'Column Name',
      dataIndex: 'name',
      render(_v, record) {
        return <div>{record.name}</div>;
      },
    },
    {
      title: 'Column Type',
      dataIndex: 'type',
      render(_v, record) {
        return <div>{record.type}</div>;
      },
    },
    {
      title: 'Rename Column To',
      dataIndex: 'renameTo',
      render(_v, record) {
        return (
          <Input
            value={record.renameTo}
            onChange={(evt) => {
              record.renameTo = evt.target.value;
              reRender();
              onChange(transformation);
            }}
          />
        );
      },
    },
    {
      title() {
        return (
          <div>
            <div>
              Filter
              <Popover
                placement="bottom"
                content={
                  <div>
                    <div>contains means like</div>
                    <div>not contains means not like</div>
                    <div>equals means =</div>
                    <div>not equals means !=</div>
                  </div>
                }
              >
                <ExclamationCircleOutlined
                  style={{
                    marginLeft: '4px',
                    color: '#949494',
                  }}
                />
              </Popover>
            </div>
          </div>
        );
      },
      width: COLUMN_FILTER_WIDTH,
      render(_v, record) {
        const filter = record.filter;
        const name = record.name;
        const isStringType = record.type === EDataModelSchemaType.STRING;
        const options = isStringType ? conditionOptions : noStringTypeConditionOptions;
        return name === 'SessionTags' ? (
          <RuleMapperForTags
            form={form}
            setting={setting}
            record={record}
            onChange={(nestedFilterFieldItemValue) => {
              record.repeatedFieldsFilter = nestedFilterFieldItemValue;
              reRender();
              onChange(transformation);
            }}
          />
        ) : (
          <RuleMapper
            conditions={options}
            value={filter ? filter : []}
            width={width}
            showTitle={false}
            columnName={record.name}
            onChange={(rule) => {
              record.filter = rule;
              reRender();
              onChange(transformation);
            }}
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render(value, record, index) {
        return <TextTruncation text={value} id={index} />;
      },
    },
  ];

  const selectedRowKeys = transformation.filter((it) => it.selected).map((it) => it.name);
  return (
    <div className={styles.settingTable}>
      <Table
        columns={columns}
        rowKey="name"
        dataSource={transformation}
        sticky={true}
        rowSelection={{
          selectedRowKeys,
          onChange(selected) {
            transformation.forEach((it) => {
              const { name } = it;
              it.selected = selected.indexOf(name) > -1;
            });
            reRender();
            onChange(transformation);
          },
        }}
        pagination={false}
        size="small"
      />
    </div>
  );
};

export default TableColumnConfig;
